import React, { useState } from 'react';
import { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { GlobalTheme } from './../../theme';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Logo from '../logo/logo';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import CloseIcon from '@mui/icons-material/Close';

import Link from '@mui/material/Link';
import { alpha, Divider, List, ListItem, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import FB from './assets/images/fb.png';
import Insta from './assets/images/insta.png';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Footer() {
    const [focused, setFocused] = useState(false);
    const [open, setOpen] = useState(false);
    const [openPP, setPPOpen] = useState(false);
    const [openDisclaimer, setDisclaimerOpen] = useState(false);
    const [openRefund, setRefundOpen] = useState(false);
    const [agreed, setAgreed] = useState(false);
    const [checked, setChecked] = useState(false);
    const [email, setEmail] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const socialIcons = [
        { id: 'sc1', path: FB, link: 'https://www.facebook.com' },
        { id: 'sc4', path: Insta, link: 'https://www.instagram.com' }
    ];

    const locationsHQ = [
        {
            name: 'Kolkata',
            address: `7/1C, Hazra Road, Flat nos. 1B,
                      1st Floor, Edcon Swastika Building,
                      Kolkata - 700 026,
                      West Bengal, India.`,
            phones: [
                { type: 'landline', number: '+91 33 4051 5000' },
                { type: 'mobile', number: '+91 98311 06004' },
            ],
        }
    ];

    const locations = [
        {
            name: 'Punjab',
            address: `104, Lawrence Road, 1st Floor, Oppo. Nehru Shopping Complex,
                      Amritsar - 143 001,
                      Punjab, India.`,
            phones: [
                { type: 'landline', number: '+91 183 2210178' },
                { type: 'landline', number: '+91 183 2210779' },
                { type: 'mobile', number: '+91 95010 66001' },
                { type: 'mobile', number: '+91 95010 66002' },
            ],
        },
        {
            name: 'Odisha',
            address: `1st Floor, Plot - 1616,
                      Lane-09, Palaspalli, near Airport,
                      Bhubaneswar - 751020,
                      Odisha, India.`,
            phones: [
                { type: 'mobile', number: '+91 93375 11598' },
            ],
        },
        {
            name: 'Maharashtra',
            address: `A wing, 305 Bhoomi Classic,
                      Link Road, Malad West,
                      Mumbai - 400 064,
                      Maharashtra, India.`,
            phones: [
                { type: 'mobile', number: '+91 98201 64048' },
            ],
        },

    ];

    // Handle the subscription process
    const handleSubscribe = async () => {
        if (!agreed) {
            setSnackbarMessage('Terms and conditions agreement is needed for subscribing.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        if (!email) {
            setSnackbarMessage('Please enter a valid email address.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        try {
            const response = await fetch('https://aaryanholidays.in/frontend/newsletter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            if (response.ok) {
                setSnackbarMessage('Newsletter request successful!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setEmail(''); // Clear the input field after successful submission
                setChecked(false); // Reset the checkbox state after submission
            } else {
                const data = await response.json(); // Get the response message
                setSnackbarMessage(data.message); // Use the message from the response
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error subscribing to newsletter:', error);
            setSnackbarMessage('Failed to submit request. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    // Close the Snackbar
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    // Open the dialog for Terms and Conditions
    const handleClickOpen = () => {
        setOpen(true);
    };

    // Close the dialog and handle agreement checkbox state
    const handleClose = (agree) => {
        if (agree) {
            setAgreed(true);
            setChecked(true); // If agreed, check the checkbox
        } else {
            setAgreed(false);
            setChecked(false); // If declined, uncheck the checkbox
        }
        setOpen(false);
    };

    // Handle the agreement checkbox change
    const handleChange = (event) => {
        setChecked(event.target.checked);
        if (!agreed) {
            handleClickOpen(); // Open the dialog if user tries to check without agreeing
        }
    };

    // Privacy policy
    const handlePrivacyPolicyOpen = () => {
        setPPOpen(true);
    };

    const handlePrivacyPolicyClose = () => {
        setPPOpen(false);
    };

    // Disclaimer
    const handleDisclaimerOpen = () => {
        setDisclaimerOpen(true);
    };

    const handleDisclaimerClose = () => {
        setDisclaimerOpen(false);
    };

    // REfund
    const handleRefundOpen = () => {
        setRefundOpen(true);
    };

    const handleRefundClose = () => {
        setRefundOpen(false);
    };


    const linksSetA = [
        { id: 'lk2', name: 'Terms & Conditions', link: handleClickOpen },
        { id: 'lk4', name: 'Privacy Policy', link: handlePrivacyPolicyOpen },
        { id: 'lk6', name: 'Refund & Cancellation', link: handleRefundOpen },
        { id: 'lk8', name: 'Disclaimer', link: handleDisclaimerOpen },
        { id: 'lk9', name: 'Blog', link: 'https://blog.aaryanholidays.in', target: '_blank' }
    ];

    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />

                <Container
                    component="footer"
                    maxWidth="xxl"
                    sx={{
                        backgroundColor: GlobalTheme.palette.common.tertiary,
                        backgroundAttachment: 'fixed',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundImage: 'url(https://as2.ftcdn.net/v2/jpg/02/65/26/83/1000_F_265268314_LmykO3vrtzmh3TQbBdnxj9vUczqqJXCU.jpg)',
                        p: { xs: 0 },

                    }}
                >
                    <Grid id='s1' sx={{ backgroundColor: `${alpha(GlobalTheme.palette.common.black, .65)}`, height: '100%' }}>
                        <Container fixed>
                            <Grid container spacing={{ sm: 3 }} justifyContent="space-between">
                                <Grid item xs={12} sm={6} md={3} sx={{ mt: 7 }}>
                                    <Logo fillColor={GlobalTheme.palette.primary.main} />
                                    <Box display='flex' flexWrap='wrap' sx={{ mt: 3 }}>
                                        <Box sx={{ mb: 2 }}>
                                            <Typography variant="h4" component='h4' sx={{ color: GlobalTheme.palette.primary.main, fontStyle: 'normal' }}>Head &amp; Corporate Office</Typography>
                                        </Box>
                                        <Grid container flexWrap='wrap' flexDirection='column'>
                                            {locationsHQ.map((location, index) => (
                                                <>
                                                    <Box component='address' id={`hQAtl${index}`} key={index} itemprop="address" itemScope itemType="https://schema.org/PostalAddress">
                                                        <Typography sx={{ width: '100%' }} fontStyle='normal' variant='subtitle1' color={GlobalTheme.palette.warning.main} ><strong>{location.name}</strong></Typography>
                                                        <Typography sx={{ width: '100%' }} fontStyle='normal' variant='subtitle1' color={GlobalTheme.palette.warning.light} itemprop="streetAddress">
                                                            {location.address.split('\n').map((line, idx) => (
                                                                <span key={idx}>{line}<br /></span>
                                                            ))}
                                                        </Typography>
                                                    </Box>
                                                    <br />
                                                    <Box display='flex' flexWrap='wrap'>
                                                        <List>
                                                            {location.phones.map((phone, idx) => (
                                                                <ListItem sx={{ display: 'flex', alignItems: 'center', pl: 0, pb: 0, pt: 1 }} key={idx}>
                                                                    <Link href={`tel:${phone.number}`} variant='subtitle1' color={GlobalTheme.palette.common.white} underline='hover'>
                                                                        {phone.type === 'landline' ? <LocalPhoneIcon variant='subtitle1' /> : <PhoneIphoneIcon variant='subtitle1' />} {phone.number}
                                                                    </Link>
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </Box>
                                                </>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={9} sx={{ pl: 0, mt: { sm: 12, md: 5, lg: 15 } }}>
                                    {/* <Typography variant="h3" component="h3" color={GlobalTheme.palette.common.white} sx={{ mb: 3 }}>
                                        For support &amp; assistance
                                    </Typography>
                                    <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                                        <CallOutlinedIcon sx={{ color: GlobalTheme.palette.common.white }} />
                                        <Link href="tel:033 4051 5000" sx={{ color: GlobalTheme.palette.common.white }} itemprop="telephone">
                                            +91 33 4051 5000
                                        </Link>
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                        <MailOutlinedIcon sx={{ color: GlobalTheme.palette.common.white }} />
                                        <Link href="mailto:info@aaryanholidays.com" sx={{ color: GlobalTheme.palette.common.white }} itemprop="email">
                                            info@aaryanholidays.com
                                        </Link>
                                    </Stack>
                                    */}

                                    <Box sx={{ mb: 2, mt: {xs: 1, md: 14.5, lg: 1.625} }}>
                                        <Typography variant="h4" component='h4' sx={{ color: GlobalTheme.palette.primary.main, fontStyle: 'normal' }}>Our Sales Offices</Typography>
                                    </Box>
                                    <Grid container>
                                        {locations.map((location, index) => (
                                            <Grid item xs={12} md={4} id={`ofAtl${index}`} key={index} paddingRight={2} marginBottom={4} >
                                                <Box component='address' itemprop="address" itemScope itemType="https://schema.org/PostalAddress">
                                                    <Typography sx={{ width: '100%' }} fontStyle='normal' variant='subtitle1' color={GlobalTheme.palette.warning.main} ><strong>{location.name}</strong></Typography>
                                                    <Typography sx={{ width: '100%' }} fontStyle='normal' variant='subtitle1' color={GlobalTheme.palette.warning.light} itemprop="streetAddress">
                                                        {location.address.split('\n').map((line, idx) => (
                                                            <span key={idx}>{line}<br /></span>
                                                        ))}
                                                    </Typography>
                                                </Box>
                                                <br />
                                                <Box display='flex' flexWrap='wrap'>
                                                    {location.phones.map((phone, idx) => (
                                                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', pl: 0, pb: { lg: 0 }, pt: 1 }} id={`caAlt${idx}`} key={idx}>
                                                            <Link id={`phAlt${idx}`} href={`tel:${phone.number}`} variant='subtitle1' color={GlobalTheme.palette.common.white} underline='hover' display='flex' itemprop="telephone">
                                                                {phone.type === 'landline' ? <LocalPhoneIcon variant='subtitle1' sx={{ mr: 1 }} /> : <PhoneIphoneIcon variant='subtitle1' sx={{ mr: 1 }} />} {phone.number}
                                                            </Link>
                                                        </Grid>
                                                    ))}
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                        <Divider sx={{ my: 3, borderColor: GlobalTheme.palette.common.grayDark, opacity: .2 }} />

                        <Container fixed sx={{ pb: 2 }}>
                            <Grid id='liAlt' container justifyContent="space-between">
                                <Grid item xs={12} md={4}>
                                    <Typography variant="h3" component="h3" color={GlobalTheme.palette.common.white} sx={{ mb: 3 }}>
                                        Links
                                    </Typography>
                                    <List sx={{ columnCount: { md: 2 }, columnWidth: { md: 3 } }}>
                                        {linksSetA.map((linkItem) => (
                                            <ListItem key={linkItem.id} sx={{ pl: 0, pb: 0 }}>
                                                {/* Check if `link` is a function (dialog handler) or a URL */}
                                                {typeof linkItem.link === 'function' ? (
                                                    // Render a Button if it’s an action (function)
                                                    <Button
                                                        onClick={linkItem.link} // Calls the function to open the dialog
                                                        id={linkItem.id}

                                                        sx={{
                                                            textAlign: 'left',
                                                            fontSize: GlobalTheme.typography.subtitle1.fontSize,
                                                            color: GlobalTheme.palette.common.white,
                                                            textDecoration: 'none',
                                                            p: 0,
                                                            fontWeight: 200,
                                                            textTransform: 'none',

                                                            '&:hover, &:focus': {
                                                                color: GlobalTheme.palette.primary.main,
                                                                backgroundColor: 'transparent',
                                                                textDecoration: 'underline',
                                                                outline: '1px solid transparent',
                                                            }
                                                        }}
                                                    >
                                                        {linkItem.name}
                                                    </Button>
                                                ) : (
                                                    // Render a Link if it’s a URL
                                                    <Link
                                                        href={linkItem.link}
                                                        id={linkItem.id}
                                                        target={linkItem.target || '_self'}

                                                        sx={{
                                                            color: GlobalTheme.palette.common.white,
                                                            textDecoration: 'none',
                                                            fontWeight: 200,
                                                            fontSize: GlobalTheme.typography.subtitle1.fontSize,
                                                            '&:hover, &:focus': {
                                                                color: GlobalTheme.palette.primary.main,
                                                                textDecoration: 'underline',
                                                                outline: '1px solid transparent',
                                                            }
                                                        }}
                                                    >
                                                        {linkItem.name}
                                                    </Link>
                                                )}
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={4} marginTop={{ xs: 2, md: 0 }}>
                                    <Typography variant="h3" component="h3" sx={{ color: GlobalTheme.palette.common.white, mb: 3 }}>
                                        Subscribe to newsletters
                                    </Typography>
                                    <Box sx={{ mt: 2 }}>
                                        <TextField
                                            id="newsletter-email"
                                            fullWidth
                                            label={!focused ? 'Your email id' : ''}
                                            placeholder="abc@xyz.com"
                                            variant="outlined"
                                            onFocus={() => setFocused(true)}
                                            onBlur={() => setFocused(false)}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            sx={{
                                                backgroundColor: alpha(GlobalTheme.palette.common.white, 0.8),
                                                borderRadius: 2
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="subscribe"
                                                            onClick={handleSubscribe}
                                                            edge="end"
                                                            size="small"
                                                            disableFocusRipple
                                                            sx={{
                                                                backgroundColor: GlobalTheme.palette.primary.main,
                                                                color: GlobalTheme.palette.common.white,
                                                                borderRadius: 2,
                                                                px: 1,
                                                                '&:hover': {
                                                                    backgroundColor: GlobalTheme.palette.primary.dark
                                                                }
                                                            }}
                                                        >
                                                            Subscribe
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <FormControlLabel
                                            control={<Checkbox checked={checked} onChange={handleChange} color="primary"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    "& .MuiSvgIcon-root": {
                                                        fill: GlobalTheme.palette.common.white
                                                    }
                                                }}
                                            />}
                                            label={
                                                <Typography variant="subtitle1" component='span' sx={{ color: GlobalTheme.palette.common.white }}>
                                                    I agree to the{' '}
                                                    <Button variant="text" onClick={handleClickOpen} sx={{ py: 0, textTransform: 'none' }}>
                                                        Terms and Conditions
                                                    </Button>
                                                </Typography>
                                            }
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>

                    <Container id='coAlt' maxWidth="xxl" sx={{ backgroundColor: `${alpha(GlobalTheme.palette.common.black, 0.75)}`, py: 1 }}>
                        <Container fixed>
                            <Stack direction={{ md: 'row' }} alignItems="center" justifyContent='space-between'>
                                <Typography variant='subtitle1' color={GlobalTheme.palette.common.grayDark} textAlign={{ xs: 'center', md: 'left' }}>
                                    &copy; Aaryan Leisure & Holidays Pvt Ltd. 2017-2023. All rights reserved.
                                </Typography>

                                <Stack direction='row' alignItems='center'>
                                    <Typography variant='subtitle1' sx={{ color: GlobalTheme.palette.common.grayDark, mr: 2 }}>Follow Us</Typography>
                                    <Stack direction="row">
                                        {socialIcons.map((icon) => (
                                            <IconButton
                                                key={icon.id}
                                                component="a"
                                                href={icon.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{
                                                    fontSize: GlobalTheme.typography.subtitle1.fontSize,
                                                    width: 40,
                                                    height: 40,
                                                    padding: 0
                                                }}
                                            >
                                                <img src={icon.path} alt={icon.id} />
                                            </IconButton>
                                        ))}

                                    </Stack>
                                </Stack>
                            </Stack>
                        </Container>
                    </Container>

                </Container>

                {/* Terms and Conditions Dialog */}
                <Dialog id='dialogTnC' open={open} onClose={handleClose} aria-labelledby="terms-dialog-title" aria-describedby="terms-dialog-description" sx={{ p: 2, borderRadius: 2 }}>
                    <DialogTitle id="terms-dialog-title" variant="h4" component="h2">
                        Terms and Conditions
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        {/* Terms and conditions content goes here */}
                        <Typography variant="body1" paragraph>
                            Welcome to the Aaryan Leisure and Holidays newsletter! By subscribing to and using our newsletter service, you agree to comply with the following terms and conditions...
                        </Typography>
                    </DialogContent>
                    <DialogActions sx={{ p: 3 }}>
                        <Button onClick={() => handleClose(true)} variant="contained" color="primary">
                            I Agree
                        </Button>
                        <Button onClick={() => handleClose(false)} variant="text" sx={{ color: GlobalTheme.palette.common.bodyTxt }}>
                            Decline
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Privacy policy */}
                <Dialog id='dialogPNp' open={openPP} onClose={handlePrivacyPolicyClose} aria-labelledby="privacy-policy-title" aria-describedby="privacy-policy-dialog" sx={{ p: 2, borderRadius: 2 }}>
                    <DialogTitle id="terms-dialog-title" variant="h4" component="h2">
                        Privacy Policy
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handlePrivacyPolicyClose}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Divider />
                    <DialogContent>
                        {/* Terms and conditions content goes here */}
                        <Typography variant="body1" paragraph>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Typography>
                    </DialogContent>
                </Dialog>

                {/* Refund */}
                <Dialog id='dialogRefund' open={openRefund} onClose={handleRefundClose} aria-labelledby="refund-title" aria-describedby="refund-dialog" sx={{ p: 2, borderRadius: 2 }}>
                    <DialogTitle id="terms-dialog-title" variant="h4" component="h2">
                        Refund & Cancellation
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleRefundClose}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Divider />
                    <DialogContent>
                        {/* Terms and conditions content goes here */}
                        <Typography variant="body1" paragraph>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Typography>
                    </DialogContent>
                </Dialog>


                {/* Disclaimer */}
                <Dialog id='dialogDisclaimer' open={openDisclaimer} onClose={handleDisclaimerClose} aria-labelledby="privacy-policy-title" aria-describedby="privacy-policy-dialog" sx={{ p: 2, borderRadius: 2 }}>
                    <DialogTitle id="terms-dialog-title" variant="h4" component="h2">
                        Disclaimer
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleDisclaimerClose}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Divider />
                    <DialogContent>
                        {/* Terms and conditions content goes here */}
                        <Typography variant="body1" paragraph>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Typography>
                    </DialogContent>
                </Dialog>

                {/* Snackbar for success/error messages */}
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </ThemeProvider >
        </React.Fragment >
    );
}
