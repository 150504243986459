import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import { GlobalTheme } from '../../theme';
import { common } from '@mui/material/colors';

export default function DiscoveryJourney() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [discoveryData, setDiscoveryData] = useState([]);
    const [swiperRef, setSwiperRef] = useState(null);

    const handleCardClick = (id) => {
        navigate(`/searchresultpage?keywords=${id}`);
    }


    useEffect(() => {
        fetch("https://aaryanholidays.in/frontend/discovery")
            .then(res => res.json())
            .then(
                (result) => {
                    setDiscoveryData(result);
                }
            );
    }, []);


    if (!Array.isArray(discoveryData) || discoveryData.length === 0) {
        return <Typography>No discovery data available.</Typography>;
    }


    return (
        <ThemeProvider theme={GlobalTheme}>
            <CssBaseline />
            <Box container spacing={4}>
                <Container maxWidth="lg" sx={{ pt: 3, pb: 3, textAlign: 'center', py: 5 }} aria-labelledby="popularToursHeading" component="section">
                    <Typography variant="h2" component="h2" color={common.black} id="popularToursHeading" sx={{ mb: 2 }}>
                    Find your type of vacation 
                    </Typography>
                </Container>
                <Swiper
                    pagination={true}
                    modules={[Pagination]}
                    style={{ paddingBottom: '40px' }}
                    onSwiper={setSwiperRef}

                    breakpoints={{
                        0: {
                            slidesPerView: 1,

                        },
                        600: {
                            slidesPerView: 2,

                        },
                        1200: {
                            slidesPerView: 4,

                        }
                    }}
                    spaceBetween={30}
                >
                    {discoveryData.map(discovery => (
                        <SwiperSlide key={discovery.id}>
                            <Card id={`discoveryCard-${discovery.id}`} sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                                <CardActionArea
                                    id={`discoveryBtn-${discovery.id}`}
                                    onClick={() => handleCardClick(discovery.discovery)}
                                    sx={{
                                        backgroundColor: GlobalTheme.palette.warning.light
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        width="255"
                                        height="240"
                                        image={`https://aaryanholidays.in/express/uploads/${discovery.image}`}
                                        alt={discovery.alt}
                                        sx={{ borderRadius: 2 }}
                                         loading='lazy'

                                    />
                                    <CardContent sx={{ textAlign: 'left', px: 0 }}>
                                        <Typography gutterBottom variant="h4" component="h3" sx={{ textTransform: 'uppercase' }}>
                                            {discovery.thumbnail_heading}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {discovery.thumbnail_desc}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>

                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
        </ThemeProvider>
    );
}