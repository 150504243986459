import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Box from '@mui/material/Box';

import CssBaseline from '@mui/material/CssBaseline';
import { alpha, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { ThemeProvider } from '@mui/material/styles';
import { Pagination } from 'swiper/modules';

import { GlobalTheme } from '../../theme';
const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

const DynamicMantle = ({ banner_type, banner_src, banner_alt, banner_title, banner_description }) => {
  return (
    <React.Fragment>
      <ThemeProvider theme={GlobalTheme}>
        <CssBaseline />
        <Card id="headerContainer"
          sx={{
            borderRadius: 0,
            position: 'relative',
            zIndex: 1,
            height: { xs: 300, md: 'auto' },
          }}>
          <Swiper
            direction={'vertical'}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              {banner_type === 'video' ? (
                <CardMedia
                key={banner_src} 
                  id="mantleMedia"
                  component="video"
                  autoPlay
                  loop
                  muted
                  playsInline
                  sx={{
                    height: { xs: 300, md: 'auto' }, // Ensures video height adapts with container
                    objectFit: 'cover',
                  }}
                >
                  <source src={banner_src} type="video/mp4" />
                  <Typography variant='subtitle2' component='p'> Your browser does not support the video tag.</Typography>
                </CardMedia>
              ) : (
                <CardMedia
                  id="mantleMedia"
                  component="img"
                  image={banner_src}
                  alt={banner_alt}
                  loading='lazy'
                  sx={{
                    height: { xs: 300, md: 'auto' },
                    objectFit: 'cover',
                  }}
                />
              )
              }
            </SwiperSlide>
          </Swiper>

          <Box
            sx={{
              position: { xs: 'absolute' },
              height: '100%',
              width: '100%',
              textAlign: 'center',
              left: 0,
              bottom: 0,
              top: 0,
              backgroundColor: {
                // xs: GlobalTheme.palette.common.black,
                xs: alpha(GlobalTheme.palette.common.black, .65)
              },
              zIndex: 2,
            }}>
            <CardContent id="mantleText"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                flexDirection: 'column',
                flexWrap: 'wrap',
                alignContent: 'center',
                pl: {
                  xs: 3,
                  md: 6
                },
                pr: {
                  xs: 3,
                  md: 8
                },
                pt: {
                  xs: 3,
                  md: 10
                }
              }}>
              <Stack>
                <Typography id="mantleHeading" gutterBottom variant='h1' color='white'
                  sx={{
                    fontSize: '2rem'
                  }}>{banner_title}</Typography>
                <Typography id="mantleSubHeading" component='p' variant="subtitle1" color='white' gutterBottom
                  sx={{
                    px: { sm: 5, lg: 20 }
                  }}>
                  {truncateText(banner_description, 200)}

                </Typography>
              </Stack>
            </CardContent>
          </Box>

          <Box
            sx={{
              background: 'linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.1) 100%)',
            }}
            width='100%'
            height='100%'
            position='absolute'>
          </Box>
        </Card>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default DynamicMantle;