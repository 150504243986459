import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Virtual, Navigation, Pagination, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

import { ThemeProvider } from '@emotion/react';
import { Button, CssBaseline } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Stack from '@mui/material/Stack';
import { GlobalTheme } from '../../theme';
import HotelStyle from './assets/scss/hotels.module.scss';

// Function to truncate text
const truncateText = (text, maxLength) => (text.length > maxLength ? `${text.substring(0, maxLength)}...` : text);

// Alert component for Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Hotels() {
    const { refer_other_id } = useParams();
    const [hotelsData, setHotelsData] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [selectedHotelTitle, setSelectedHotelTitle] = useState('');
    const [selectedHotelId, setSelectedHotelId] = useState(null);
    const [user, setUser] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [swiperRef, setSwiperRef] = useState(null);

    // Open dialog with selected hotel details
    const handleClickOpen = (title, id) => {
        setSelectedHotelTitle(title);
        setSelectedHotelId(id);
        setOpen(true);

        // Get user details from localStorage if available
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    // Fetch hotels data on component mount
    useEffect(() => {
        const fetchHotelsData = async () => {
            try {
                const response = await fetch(`https://aaryanholidays.in/frontend/special-feature-package-wise/${refer_other_id}`);
                const data = await response.json();
                console.log(data);
                if (Array.isArray(data)) {
                    const hotelData = data.filter(item => item.type === 'Hotel').map(item => ({
                        ...item,
                        image: `https://aaryanholidays.in/express/uploads/${item.thumbnail}`
                    }));
                    setHotelsData(hotelData);
                } else {
                    console.error('Unexpected data format:', data);
                }
            } catch (error) {
                console.error('Error fetching hotels data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchHotelsData();
    }, [refer_other_id]);

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());

        // Create an object for the API request
        const enquiryData = {
            hotel_id: selectedHotelId,
            name: formJson.name,
            email: formJson.email,
            phone: formJson.phone || null,
            enquiry: formJson.enquiry,
        };

        try {
            const response = await fetch('https://aaryanholidays.in/frontend/hotel-enquiries', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(enquiryData),
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Enquiry submitted successfully:', data);
                setSnackbarMessage('Your enquiry has been submitted successfully!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            } else {
                const errorData = await response.json();
                console.error('Error submitting enquiry:', errorData);
                setSnackbarMessage('Failed to submit enquiry. Please try again.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setSnackbarMessage('Failed to submit enquiry. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }

        handleClose();
    };

    if (loading) {
        return
        <>
            <Skeleton variant="rectangular" width={210} height={118} />
            <Box sx={{ pt: 0.5 }}>
                <Skeleton />
                <Skeleton width="60%" />
            </Box>
        </>;
    }

    if (!Array.isArray(hotelsData) || hotelsData.length === 0) {
        return <Typography>No hotels data available.</Typography>;
    }

    return (
        <ThemeProvider theme={GlobalTheme}>
            <CssBaseline />
            <Swiper
                className={HotelStyle.hotels}
                modules={[Virtual, Navigation, Pagination, Keyboard]}
                onSwiper={setSwiperRef}
                breakpoints={{
                    0: { slidesPerView: 1 },
                    600: { slidesPerView: 2 },
                    900: { slidesPerView: 3 },
                    1200: { slidesPerView: 4 }
                }}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}

                freeMode={true}
                grabCursor={true}
                keyboard={{ enabled: true }}
            >
                {hotelsData.map((hotel, index) => (
                    <SwiperSlide id={hotel.id} key={hotel.id} virtualIndex={index}>
                        <Card
                            id={`hotelsCard-${hotel.id}`}
                            sx={{
                                boxShadow: '0 0 1px 1px rgba(0,0,0,0.1)',
                                p: 0,
                                mb: 3,
                                "&:hover": {
                                    boxShadow: '0 0 30px 5px rgba(0,0,0,0.2)',
                                }
                            }}
                            itemScope itemType="https://schema.org/Hotel"
                        >
                            <CardContent
                                id={`hotelsBtn-${hotel.id}`}
                                component='figure'
                                sx={{
                                    p: 0,
                                    m: 0,
                                    borderRadius: 2,
                                    "&:hover": {
                                        backgroundColor: 'transparent',
                                        boxShadow: 'none'
                                    }
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    height="153"
                                    width="220"
                                    image={hotel.image}
                                    alt={hotel.title}
                                    sx={{
                                        borderTopLeftRadius: 1,
                                        borderTopRightRadius: 1,
                                        borderBottomRightRadius: 0,
                                        borderBottomLeftRadius: 0,
                                    }}
                                    itemprop="photo"
                                     loading='lazy'
                                />
                                <CardContent component='figcaption' sx={{ textAlign: 'left', px: 2 }}>
                                    <Typography gutterBottom variant="h4" component="h3" sx={{ textTransform: 'uppercase', mb: 2 }} itemprop="name">
                                        {hotel.title}
                                    </Typography>
                                    <Stack component='address' spacing={1} direction='row' sx={{ mb: 2, fontStyle: 'normal', minHeight: 50 }}>
                                        <LocationOnOutlinedIcon />
                                        <Typography variant='subtitle2' component='p' itemprop="description">
                                            {truncateText(hotel.description, 50)}
                                        </Typography>
                                    </Stack>
                                    <Button variant='outlined' sx={{ borderRadius: 5 }} onClick={() => handleClickOpen(hotel.title, hotel.id)}>
                                        Enquiry
                                    </Button>
                                </CardContent>
                            </CardContent>
                        </Card>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* Enquiry Form Dialog */}
            <Dialog open={open} onClose={handleClose} PaperProps={{ component: 'form', onSubmit: handleSubmit }}>
                <DialogTitle>{selectedHotelTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Tell us what you want to know about the hotel and we are glad to help you.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="normal"
                        id="name"
                        name="name"
                        label="Your name"
                        type="text"
                        fullWidth
                        variant="standard"
                        defaultValue={user?.firstName || ''}
                        placeholder="e.g: John Smith"
                    />
                    <TextField
                        required
                        margin="normal"
                        id="email"
                        name="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                        defaultValue={user?.email || ''}
                        placeholder="e.g: john.smith@domain.com"
                    />
                    <TextField
                        margin="normal"
                        id="phone"
                        name="phone"
                        label="Phone"
                        type="tel"
                        fullWidth
                        variant="standard"
                        defaultValue={user?.phone || ''}
                        placeholder="e.g: +91 00000 00000"
                    />
                    <TextField
                        id="msg"
                        margin="normal"
                        required
                        multiline
                        minRows={3}
                        maxRows={6}
                        label="Enquiry"
                        name="enquiry"
                        fullWidth
                        placeholder="What you would like to enquire about"
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                    <Button onClick={handleClose} sx={{ textTransform: 'capitalize' }}>Cancel</Button>
                    <Button type="submit" variant="contained" sx={{ textTransform: 'capitalize', borderRadius: 5 }}>Enquire</Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for success/error messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider >
    );
}
