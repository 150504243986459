import React, { useState, useEffect, useRef } from 'react';
import { ThemeProvider, alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import List from '@mui/material/List';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import Link from '@mui/material/Link';
import Logo from '../logo/logo';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar'; // Import Snackbar

import Logout from '@mui/icons-material/Logout';
import { GlobalTheme } from '../../theme';

import { useNavigate } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';

import { Link as rlink } from 'react-router-dom';

import NavbarStyle from './assets/scss/navbar.module.scss';
import './assets/scss/menu.css';
// import './assets/scss/menu-transitions.css';
import './assets/scss/megamenu.scss';
import TravelRequest from './travelRequest';

const drawerWidth = '100%';
const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

function DrawerAppBar(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const isMobile = useMediaQuery(GlobalTheme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('md'));
  const { window } = props;
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    handleMenuOpen();
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const container = window !== undefined ? () => window().document.body : undefined;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
    target: window ? window() : undefined,
  });

  useEffect(() => {
    // Check localStorage for user info
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('user');
    setUser(null);
    googleLogout();

    // Open snackbar on logout
    setSnackbarOpen(true);
  };

  // Close the snackbar after a few seconds or manually
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };


  const BASE_MEDIA_URL = 'https://aaryanholidays.in/express/uploads/';
  const [destinationData, setDestinationData] = useState([]);
  const [splDestinationData, setsplDestinationData] = useState([]);
  const [continentsData, setcontinentsData] = useState([]);
  const [tourTypeData, setallTourTypeData] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [menuRoute, setMenuRoute] = useState('');
  const [openMenuId, setOpenMenuId] = useState(null);
  const [expanded, setExpanded] = React.useState('panel1');
  const [expandedParent, setExpandedParent] = useState(false);
  const [expandedChild, setExpandedChild] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const scrollRef = useRef(null);

  const handleMenuClose = () => {
    setMenuOpen(false); // Close the menu
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
    handleDrawerClose();
  };

  const handleMenuOpen = () => {
    setMenuOpen(true); // Open the menu
  };
  const whichroute = (curroute) => {
    // console.log("whichroute called with:", curroute);
    // alert(curroute);
    setMenuRoute(curroute); // Open the menu

  };

  // const for style object
  const accordionStyles = {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: `1px solid ${GlobalTheme.palette.common.grayDark}`,
    mb: 0,
    borderRadius: 0,
    "& .MuiPaper-root-MuiAccordion-root::before": {
      display: 'none'
    },
    "&.Mui-expanded": {
      my: 0
    }
  };

  const accordionDetailsStyles = {
    p: 0
  };

  const gridStyles = {
    pl: 2,
    mb: 2.62
  };

  // Define a constant for the button styles
  const buttonStyles = {
    variant: 'outlined',
    size: 'small'
  };

  const handleParentChange = (panel) => (event, isExpanded) => {
    setExpandedParent(isExpanded ? panel : false);
  };

  const handleChildChange = (panel) => (event, isExpanded) => {
    setExpandedChild(isExpanded ? panel : false);
  };

  // mobile static links
  const linkItems = [
    { href: "/about", label: "About us" },
    { href: "https://blog.aaryanholidays.in", label: "Blog" },
    { href: "/contact", label: "Contact us" },
  ];


  useEffect(() => {
    const fetchData = async () => {
      try {
        const destinationsResponse = await fetch('https://aaryanholidays.in/frontend/alldestinations');
        const destinationsData = await destinationsResponse.json();
        const destinationsDataspl = destinationsData;
        const promotedData = destinationsData.filter(destination => destination.promote === 1);
        const splData = destinationsDataspl.filter(destination => destination.is_specialized === 1);
        console.log(splData);


        setDestinationData(promotedData);
        setsplDestinationData(splData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const continentsResponse = await fetch('https://aaryanholidays.in/frontend/continents');
        const continentsData = await continentsResponse.json();

        setcontinentsData(continentsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const allTourTypeResponse = await fetch('https://aaryanholidays.in/frontend/all-tour-types');
        const allTourTypeData = await allTourTypeResponse.json();

        setallTourTypeData(allTourTypeData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <ThemeProvider theme={GlobalTheme}>
        <CssBaseline />
        <Box sx={{ display: 'flex' }}>
          <AppBar
            sx={{
              width: '100%',
              backgroundColor: {
                xs: GlobalTheme.palette.common.white,
                md: trigger ? GlobalTheme.palette.common.white : 'transparent',
              },
              position: {
                xs: 'relative',
                md: 'fixed',
              },
              top: 0,
              boxShadow: trigger ? GlobalTheme.shadows[4] : 'none',
              transition: 'position .5s ease-in-out, box-shadow 0.3s ease-in-out, opacity 0.6s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out',

              ...(isDesktop && {
                '.navPrimary': {
                  transition: 'background-color 0.3s ease-in-out',
                },

                '.navSecondary': {
                  transition: 'background-color 0.3s ease-in-out',
                  transitionDelay: '.2s',
                  boxShadow: 'none',
                },

                '&:hover': {
                  '.navPrimary': {
                    backgroundColor: alpha(GlobalTheme.palette.common.white, 0.8),

                    '.active >': {
                      'a': {
                        color: GlobalTheme.palette.common.white,
                      },
                    },

                    'a': {
                      color: GlobalTheme.palette.common.black,
                    },
                  },

                  '.navSecondary': {
                    backgroundColor: alpha(GlobalTheme.palette.common.white, 0.2),
                  },
                },
              }),
            }}
          >
            <Toolbar
              sx={{
                justifyContent: 'space-between',
                px: { md: 0, width: '100%' },
                minHeight: {
                  xs: 'auto',
                },
              }}
              id="navbar"
              {...(isDesktop && {
                component: 'nav',
                'aria-label': 'primary navigation',
              })}
            >
              {isMobile && (
                <>
                  <Grid
                    id="menuPrimaryMobile"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      width: '100%',
                      display: 'flex',
                      py: 1.5,
                    }}
                  >
                    <Grid onClick={handleDrawerClose}>
                      <Logo />
                    </Grid>

                    <Grid>
                      <IconButton
                        id="mobileMenu"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}

                      >
                        <MenuIcon />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Drawer
                    container={container}
                    variant="temporary"
                    anchor="top"
                    open={open}
                    id="navMobile"
                    ModalProps={{
                      keepMounted: true,
                    }}
                    sx={{
                      '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, height: '100%' },
                    }}
                  >
                    <Box component="nav" aria-label="mobile navigation">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{
                          p: 2,
                        }}
                      >
                        <Grid onClick={handleDrawerClose}>
                          <Logo />
                        </Grid>
                        <Stack direction="row" spacing={3} alignItems="center">
                          <Link
                            href="/login"
                            variant="outlined"
                            disableFocusRipple
                            sx={{
                              color: GlobalTheme.palette.primary.main,
                            }}
                          >
                            Sign in
                          </Link>

                          <IconButton onClick={handleDrawerClose}>
                            <CloseOutlinedIcon />
                          </IconButton>
                        </Stack>
                      </Stack>
                      <Divider />

                      <div ref={scrollRef}></div>

                      <List id='mobileNavSetA'>
                        <ListItem sx={{ py: 0, borderBottom: '1px solid', borderBottomColor: GlobalTheme.palette.common.grayDark }}>
                          <Link onClick={handleDrawerClose} component={rlink} to="/" sx={{ color: GlobalTheme.palette.common.black, width: '100%', py: 1.5 }} underline="none"> Home
                          </Link>
                        </ListItem>
                        <Accordion component='li' expanded={expandedParent === 'panel1'} onChange={handleParentChange('panel1')} sx={{
                          ...accordionStyles
                        }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="toursContent"
                            id="toursHeader"
                            sx={{
                              backgroundColor: expandedParent === 'panel1' ? 'primary.main' : '',
                              color: expandedParent === 'panel1' ? 'common.white' : ''
                            }}
                          >
                            Tours
                          </AccordionSummary>

                          <AccordionDetails sx={accordionDetailsStyles} >
                            <Accordion expanded={expandedChild === 'panel1A'} onChange={handleChildChange('panel1A')} elevation={0} sx={accordionDetailsStyles}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="popularToursContent"
                                id="popularToursHeader"
                                sx={{
                                  px: 2,
                                  backgroundColor: expandedChild === 'panel1A' ? 'common.grayLight' : ''
                                }}>
                                Popular Tours
                              </AccordionSummary>
                              <AccordionDetails sx={{ px: 2, accordionDetailsStyles }}>
                                <Typography variant='subtitle1' component='p' fontWeight={600} gutterBottom>What's Hot</Typography>
                                <List sx={{ columnCount: 2 }}>
                                  {destinationData.map((tour, index) => (
                                    <ListItem id={`desti${index}`} sx={{ p: 0, mb: 2 }}>
                                      <Link onClick={handleDrawerClose} component={rlink} to={`/tour-details/4/${tour.id}`} className='position-relative' sx={{ width: '100%' }}>
                                        {tour.type === 'video' ? (
                                          <video
                                            src={`${BASE_MEDIA_URL}${tour.src}`}
                                            className={NavbarStyle.mobileLockHeight}
                                          />
                                        ) : (
                                          <img
                                            src={`${BASE_MEDIA_URL}${tour.src}`}
                                            alt={tour.alt || 'Tour Image'}
                                            className={NavbarStyle.mobileLockHeight}
                                          />
                                        )}
                                        <span className="aaryan-c-title borderRadius" dangerouslySetInnerHTML={{ __html: tour.thumbnail_heading }} />
                                      </Link>
                                    </ListItem>
                                  ))}
                                </List>
                              </AccordionDetails>
                            </Accordion>

                            {continentsData.map((cnt, index) => (
                              <Accordion key={index} expanded={expandedChild === `panel1A${index}`} onChange={handleChildChange(`panel1A${index}`)} sx={{
                                ...accordionStyles
                              }}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls={`panel1A${index}-content`}
                                  id={`panel1A${index}-header`}
                                  sx={{
                                    backgroundColor: expandedChild === `panel1A${index}` ? 'common.grayLight' : ''
                                  }}>
                                  {cnt.continent}
                                </AccordionSummary>
                                <AccordionDetails sx={accordionDetailsStyles}>
                                  <Grid item xs={12} md={6}
                                    sx={{
                                      backgroundColor: GlobalTheme.palette.common.tertiary,
                                      color: GlobalTheme.palette.common.white
                                    }}>
                                    <img src={`https://aaryanholidays.in/express/uploads/${cnt.continent_banner}`} alt='some text' className='borderRadius' style={{ height: 150, width: '100%' }} />
                                    <Grid sx={{
                                      p: 3
                                    }}>
                                      <Typography component='h3' variant='h3' sx={{ mt: 1, mb: 2 }}>{cnt.continent_heading}</Typography>
                                      <Typography variant='p' component='p'>
                                        {truncateText(cnt.continent_description, 80)}
                                      </Typography>
                                    </Grid>
                                  </Grid>

                                  <List
                                    sx={{
                                      mt: 2,
                                      p: 0,
                                      columnCount: 2
                                    }}>
                                    {tourTypeData.map((tourtypes, index) => (
                                      tourtypes.continent === cnt.id && (
                                        <ListItem key={index}>
                                          <Link onClick={handleDrawerClose}
                                            component={rlink} to={`/tour-type/3/${tourtypes.id}`} // Use 'to' for React Router's Link
                                            underline="hover"
                                            sx={{
                                              color: GlobalTheme.palette.common.black,

                                              "&:hover": {
                                                color: GlobalTheme.palette.primary.main,
                                              },
                                            }}
                                          >
                                            {tourtypes.tour_type}
                                          </Link>
                                        </ListItem>
                                      )
                                    ))}
                                  </List>
                                  <Box sx={gridStyles}>
                                    <Link onClick={handleDrawerClose} {...buttonStyles} component={rlink} to={`/continent/3/${cnt.id}`}>View all</Link>
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            ))}
                          </AccordionDetails>
                        </Accordion>

                        <Accordion component='li' expanded={expandedParent === 'panel2'} onChange={handleParentChange('panel2')} sx={accordionStyles}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="specialPackage"
                            id="specialPackageHeader"
                            sx={{
                              backgroundColor: expandedParent === 'panel2' ? 'primary.main' : '',
                              color: expandedParent === 'panel2' ? 'common.white' : ''
                            }}
                          >
                            Special Package
                          </AccordionSummary>

                          <AccordionDetails sx={accordionDetailsStyles}>
                            {tourTypeData.map((country, index) => {
                              const relatedSpecialDestinations = splDestinationData.filter(dest => dest.tour_type_id === country.id);
                              const firstActiveIndex = tourTypeData.findIndex(country => {
                                const relatedSpecialDestinations = splDestinationData.filter(dest => dest.tour_type_id === country.id);
                                return relatedSpecialDestinations.length > 0;
                              });
                              if (relatedSpecialDestinations.length === 0) return null; // Skip if no tours
                              return (


                                <Accordion key={index} expanded={expandedChild === `panel2A${index}`} onChange={handleChildChange(`panel2A${index}`)} sx={accordionStyles}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel2A${index}-content`}
                                    id={`panel2A${index}-header`}
                                    sx={{
                                      backgroundColor: expandedChild === `panel2A${index}` ? 'common.grayLight' : ''
                                    }}>
                                    {country.tour_type}
                                  </AccordionSummary>
                                  <AccordionDetails sx={accordionDetailsStyles}>
                                    <Grid item xs={12} md={6}
                                      sx={{
                                        backgroundColor: GlobalTheme.palette.common.tertiary,
                                        color: GlobalTheme.palette.common.white
                                      }}>
                                      {country.banner_type === 'video' ? (
                                        <video
                                          src={`${BASE_MEDIA_URL}${country.banner_src}`}
                                          autoPlay
                                          style={{ height: 150, width: '100%', objectFit: 'cover' }}
                                        />
                                      ) : (
                                        <img
                                          src={`${BASE_MEDIA_URL}${country.banner_src}`}
                                          alt=""
                                          style={{ height: 150, width: '100%', objectFit: 'cover' }}
                                        />
                                      )}
                                      <Grid sx={{
                                        p: 3
                                      }}>
                                        <Typography component='h3' variant='h3' sx={{ mt: 1, mb: 2 }}>{country.heading}</Typography>
                                        <Typography variant='p' component='p'>
                                          {truncateText(country.desc_txt, 80)}
                                        </Typography>
                                      </Grid>
                                    </Grid>

                                    <List
                                      sx={{
                                        mt: 2,
                                        p: 0,
                                        columnCount: 2
                                      }}>
                                      {splDestinationData.map((spltour, index) => (
                                        spltour.tour_type_id === country.id && (
                                          <ListItem key={index}>
                                            <Link onClick={handleDrawerClose}
                                              component={rlink} to={`/tour-details/4/${spltour.id}`}
                                              underline="hover"
                                              sx={{
                                                color: GlobalTheme.palette.common.black,

                                                "&:hover": {
                                                  color: GlobalTheme.palette.primary.main,
                                                },
                                              }}
                                            >
                                              {spltour.thumbnail_heading}
                                            </Link>
                                          </ListItem>
                                        )
                                      ))}
                                    </List>
                                    <Box sx={{
                                      pl: 2,
                                      mb: 2.62
                                    }}>
                                      <Button onClick={handleDrawerClose} {...buttonStyles}>View all</Button>
                                    </Box>
                                  </AccordionDetails>
                                </Accordion>
                              )
                            })}
                          </AccordionDetails>
                        </Accordion>

                        {linkItems.map((item, index) => (
                          <ListItem key={index} sx={{ py: 0, borderBottom: '1px solid', borderBottomColor: GlobalTheme.palette.common.grayDark }}>
                            <Link onClick={handleDrawerClose} component={rlink} to={item.href} sx={{ color: GlobalTheme.palette.common.black, width: '100%', py: 1.5 }} underline="none">{item.label}</Link>
                          </ListItem>
                        ))}
                      </List >

                      <Paper id='mobileNavSetB' sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                        <BottomNavigation
                          showLabels
                          sx={{ justifyContent: 'space-between' }}
                        >
                          <BottomNavigationAction label="Call Us" icon={<SupportAgentOutlinedIcon />} />
                          <BottomNavigationAction label="Email Us" icon={<MailOutlinedIcon />} />
                          <TravelRequest size='medium' />
                        </BottomNavigation>

                      </Paper>

                    </Box>
                  </Drawer>
                </>
              )}

              {isDesktop && (
                <Grid
                  sx={{
                    width: '100%',
                    display: 'block',
                  }}
                >
                  <Grid
                    id="navSecondary"
                    alignItems="center"
                    justifyContent="space-between"
                    className="navSecondary"
                    sx={{
                      display: {
                        xs: 'none',
                        md: trigger ? 'none' : 'flex',
                      },
                      transition: 'display .3s fadeOut',
                      boxShadow: `0 0 0 1px ${alpha(GlobalTheme.palette.common.white, 0.2)}`,
                    }}
                  >
                    <List sx={{ display: 'inline-flex' }}>
                      <ListItem disablePadding>
                        <ListItemButton
                          component="a"
                          href="tel:+91 033 4051 5000"
                          sx={{
                            color: GlobalTheme.palette.common.white,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 'auto',
                              color: GlobalTheme.palette.common.grayDark,
                              mr: 1,
                            }}
                          >
                            <PhoneOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary="91 33 4051 5000" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton
                          component="a"
                          href="mailto:tours.ccu@aaryanholidays.com"
                          sx={{
                            color: GlobalTheme.palette.common.white,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color: GlobalTheme.palette.common.grayDark,
                              minWidth: 'auto',
                              mr: 1,
                            }}
                          >
                            <MailOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary="tours.ccu@aaryanholidays.com" />
                        </ListItemButton>
                      </ListItem>
                    </List>

                    <List sx={{ display: 'inline-flex' }}>
                      <ListItem
                        sx={{
                          px: 1.5,
                          py: 0,
                        }}
                      >
                        <Link href="https://www.facebook.com" target="_blank">
                          <FacebookIcon
                            sx={{
                              color: trigger ? GlobalTheme.palette.common.black : GlobalTheme.palette.common.white,
                            }}
                          />
                        </Link>
                      </ListItem>
                      <ListItem
                        sx={{
                          px: 1.5,
                          py: 0,
                        }}
                      >
                        <Link href="https://www.instagram.com" target="_blank">
                          <InstagramIcon
                            sx={{
                              color: trigger ? GlobalTheme.palette.common.black : GlobalTheme.palette.common.white,
                            }}
                          />
                        </Link>
                      </ListItem>
                      <ListItem sx={{ px: 1.5, py: 0 }}>
                        {!user ? (
                          <Link
                            href="/login"
                            variant="outlined"
                            disableFocusRipple
                            sx={{
                              minWidth: 50,
                              color: {
                                xs: GlobalTheme.palette.common.white,
                                md: GlobalTheme.palette.common.white,
                              },
                            }}
                          >
                            Sign in
                          </Link>
                        ) : (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip title="Account settings">
                              <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={openMenu ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openMenu ? 'true' : undefined}
                              >
                                <Avatar sx={{ width: 32, height: 32, backgroundColor: '#B71C1C' }}>
                                  <Typography variant="body1" sx={{ color: GlobalTheme.palette.common.white }}>
                                    {user.firstName.charAt(0)}
                                    {user.lastName.charAt(0)}
                                  </Typography>
                                </Avatar>
                              </IconButton>
                            </Tooltip>

                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={openMenu}
                              onClose={handleClose}
                              onClick={handleClose}
                              slotProps={{
                                paper: {
                                  elevation: 0,
                                  sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1,
                                    },
                                    '&::before': {
                                      content: '""',
                                      display: 'block',
                                      position: 'absolute',
                                      top: 0,
                                      right: 14,
                                      width: 10,
                                      height: 10,
                                      bgcolor: 'background.paper',
                                      transform: 'translateY(-50%) rotate(45deg)',
                                      zIndex: 0,
                                    },
                                  },
                                },
                              }}
                              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                              <MenuItem onClick={handleClose} sx={{ mt: 1 }}>
                                <ManageAccountsOutlinedIcon fontSize="small" sx={{ mr: 2 }} />
                                Profile
                              </MenuItem>
                              <Divider sx={{ my: 0 }} />
                              <MenuItem onClick={handleLogout} sx={{ mb: 1 }}>
                                <ListItemIcon>
                                  <Logout fontSize="small" />
                                </ListItemIcon>
                                Logout
                              </MenuItem>
                            </Menu>
                          </Box>
                        )}
                      </ListItem>
                    </List>
                  </Grid>

                  <Grid
                    id="navPrimary"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    className="navPrimary"
                    sx={{ display: 'flex', width: '100%' }}
                  >
                    <Grid className="aaryan-wrapper" display='flex' justifyContent='space-between' alignItems='center'
                      sx={{
                        boxShadow: `0 0 0 1px ${alpha(GlobalTheme.palette.common.white, 0.2)}`

                      }} >
                      <List className="aaryan-menu" id='secA'>
                        <li className={`aaryan-menu-mega-blog aaryan-menu-mega-blog-parent   ${menuRoute === '' ? 'active' : ''}`}>
                          <Link onClick={() => whichroute('')} component={rlink} to="/"
                            className={trigger ? 'scrolled' : ''}
                          > Home
                          </Link>
                        </li>

                        <li className={`aaryan-menu-mega-blog aaryan-menu-mega-blog-parent  ${menuRoute === 'Tour' ? 'active' : ''}`}>
                          <Link onClick={handleMenuClose} component={rlink} to="#"
                            className={trigger ? 'scrolled' : ''}
                            onMouseEnter={handleMenuOpen}
                            onMouseLeave={handleMenuClose} >Tours</Link>
                          {menuOpen && (
                            <>
                              <div className={`minHeight ${menuOpen ? 'open' : 'closed'}`} onMouseEnter={handleMenuOpen}
                                onMouseLeave={handleMenuClose} onClick={handleMenuClose}>
                                <List className="aaryan-menu-mega-blog-nav">
                                  <li className="aaryan-active-menu-item">
                                    <Link onClick={handleMenuClose} component={rlink} to="#">Popular Tours</Link>

                                    <div className="aaryan-grid aaryan-grid-lined bgGray minHeight">
                                      <div className="aaryan-row">
                                        <Typography variant='h3' component='h2' sx={{ ml: 2, mt: 2 }} gutterBottom>What's Hot</Typography>
                                        {destinationData.map((tour, index) => (
                                          <div className="aaryan-col-3 aaryan-col-3__no-border">
                                            <Link onClick={() => whichroute('Tour')} component={rlink} to={`/tour-details/4/${tour.id}`} className='position-relative hover' sx={{ display: 'flex' }}>
                                              {tour.type === 'video' ? (
                                                <video
                                                  src={`${BASE_MEDIA_URL}${tour.src}`}
                                                />
                                              ) : (
                                                <img
                                                  src={`${BASE_MEDIA_URL}${tour.src}`}
                                                  alt={tour.alt || 'Tour Image'}
                                                />
                                              )}

                                              <span className="aaryan-c-title borderRadius" dangerouslySetInnerHTML={{ __html: tour.thumbnail_heading }} />
                                            </Link>
                                          </div>
                                        ))}

                                      </div>
                                    </div>

                                    <span className="aaryan-dropdown-toggle"></span>
                                  </li>


                                  {continentsData.map((cnt, index) => (
                                    <li className="aaryan-menu-item hidden-md">
                                      <Link onClick={handleMenuClose} component={rlink} to="#">{cnt.continent}</Link>
                                      <Grid className="aaryan-grid aaryan-grid-lined bgGray minHeight" >
                                        <Grid container className='minHeight'>
                                          <Grid item xs={12} md={6} position='relative'>
                                            <List
                                              sx={{
                                                py: 3.75,
                                                pb: 1.25,
                                                px: 3.12,
                                                columnCount: 2
                                              }}>
                                              {tourTypeData.map((tourtypes, index) => (
                                                tourtypes.continent === cnt.id && ( // Conditional rendering in JSX
                                                  <ListItem key={index}> {/* Added key for each list item */}
                                                    <Link
                                                      onClick={() => whichroute('Tour')}
                                                      component={rlink}
                                                      to={`/tour-type/3/${tourtypes.id}`}
                                                      underline="hover"
                                                      sx={{
                                                        color: `${GlobalTheme.palette.common.tertiary} !important`, // Initial color with !important
                                                        "&:hover": {
                                                          color: `${GlobalTheme.palette.primary.main} !important`, // Hover color with !important
                                                        },
                                                      }}
                                                    >
                                                      {tourtypes.tour_type}
                                                    </Link>
                                                  </ListItem>
                                                )
                                              ))}

                                            </List>
                                            <Box sx={{
                                              pl: 5,
                                              mt: 2.12,
                                              mb: 2.62
                                            }}>
                                              <Button onClick={() => whichroute('Tour')} {...buttonStyles} component={rlink} onClick={handleMenuClose} to={`/continent/3/${cnt.id}`}
                                                sx={{
                                                  color: `${GlobalTheme.palette.common.tertiary} !important`, // Initial color with !important
                                                  "&:hover": {
                                                    color: `${GlobalTheme.palette.primary.main} !important`, // Hover color with !important
                                                  },
                                                }}
                                                >View all</Button>
                                            </Box>
                                          </Grid>
                                          <Grid item xs={12} md={6}
                                            sx={{
                                              backgroundColor: GlobalTheme.palette.common.tertiary,
                                              color: GlobalTheme.palette.common.white
                                            }}>
                                            <img src={`https://aaryanholidays.in/express/uploads/${cnt.continent_banner}`} alt='some text' className='borderRadius' />
                                            <Grid sx={{
                                              p: 3
                                            }}>
                                              <Typography component='h3' variant='h3' sx={{ mt: 1, mb: 2 }}>{cnt.continent_heading}</Typography>
                                              <Typography variant='p' component='p'>
                                                {truncateText(cnt.continent_description, 190)}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <span className="aaryan-dropdown-toggle"></span>
                                    </li>
                                  ))}



                                </List>
                              </div>
                            </>
                          )}
                          <span className="aaryan-dropdown-toggle"></span>
                        </li>

                        <li className={`aaryan-menu-mega-blog aaryan-menu-mega-blog-parent ${menuRoute === 'SplTour' ? 'active' : ''}`}>
                          <Link component={rlink} to="#"
                            className={trigger ? 'scrolled' : ''}
                            onMouseEnter={handleMenuOpen}
                            onMouseLeave={handleMenuClose} >Special Package</Link>

                          {menuOpen && (
                            <>
                              <div className="minHeight" onMouseEnter={handleMenuOpen}
                                onMouseLeave={handleMenuClose} onClick={handleMenuClose}>
                                <List className="aaryan-menu-mega-blog-nav">

                                  {tourTypeData.map((country, index) => {
                                    const relatedSpecialDestinations = splDestinationData.filter(dest => dest.tour_type_id === country.id);
                                    const firstActiveIndex = tourTypeData.findIndex(country => {
                                      const relatedSpecialDestinations = splDestinationData.filter(dest => dest.tour_type_id === country.id);
                                      return relatedSpecialDestinations.length > 0;
                                    });
                                    if (relatedSpecialDestinations.length === 0) return null; // Skip if no tours
                                    return (
                                      <li className={index === firstActiveIndex ? "aaryan-active-menu-item" : "aaryan-menu-item"} key={country.id}>
                                        <Link component={rlink} to="#">{country.tour_type}</Link>
                                        <Grid className="aaryan-grid aaryan-grid-lined bgGray minHeight" >
                                          <Grid container className='minHeight'>
                                            <Grid item xs={12} md={6} position='relative'>
                                              <List
                                                sx={{
                                                  py: 3.75,
                                                  pb: 1.25,
                                                  px: 3.12,
                                                  columnCount: 2
                                                }}>
                                                {splDestinationData.map((spltour) => (
                                                  spltour.tour_type_id === country.id && (
                                                    <ListItem key={spltour.id}>
                                                      <Link onClick={() => whichroute('SplTour')} component={rlink} to={`/tour-details/4/${spltour.id}`} target="_self" underline="hover" color='common.tertiary'
                                                        sx={{
                                                          "&:hover": {
                                                            color: GlobalTheme.palette.primary.main
                                                          }
                                                        }}>
                                                        {spltour.thumbnail_heading}
                                                      </Link>
                                                    </ListItem>
                                                  )
                                                ))}
                                              </List>
                                            </Grid>
                                            <Grid item xs={12} md={6}
                                              sx={{
                                                backgroundColor: GlobalTheme.palette.common.tertiary,
                                                color: GlobalTheme.palette.common.white
                                              }}
                                            >
                                              {country.banner_type === 'video' ? (
                                                <video
                                                  src={`${BASE_MEDIA_URL}${country.banner_src}`}
                                                />
                                              ) : (
                                                <img
                                                  src={`${BASE_MEDIA_URL}${country.banner_src}`}
                                                  alt=""
                                                />
                                              )}
                                              <Grid sx={{
                                                p: 3
                                              }}>
                                                <Typography component='h3' variant='h3' sx={{ mt: 1, mb: 2 }}>{country.heading}</Typography>
                                                <Typography variant='p' component='p'>
                                                  {truncateText(country.desc_txt, 190)}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <span className="aaryan-dropdown-toggle"></span>
                                      </li>
                                    )
                                  }


                                  )}
                                </List>
                              </div>
                            </>
                          )}
                          <span className="aaryan-dropdown-toggle"></span>
                        </li>

                        <li className={`aaryan-menu-mega-blog-parent  ${menuRoute === 'About' ? 'active' : ''}`}>
                          <Link onClick={() => whichroute('About')} component={rlink} to="/about" className={trigger ? 'scrolled' : ''}>About us</Link>
                        </li>


                      </List>
                      <List className="aaryan-menu" id='secB' itemScope itemType="https://schema.org/Organization">
                        <li className='pr-0' onClick={() => whichroute('')}>
                          <Logo />
                        </li>
                      </List>
                      <List className="aaryan-menu" id='secC' sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        <li className='aaryan-menu-mega-blog-parent'>
                          <Link component={rlink} to="https://blog.aaryanholidays.in" target="_blank" rel="noopener noreferrer" className={trigger ? 'scrolled' : ''}>Blog</Link>
                        </li>

                        <li className={`aaryan-menu-mega-blog-parent pr-0 ${menuRoute === 'Contact' ? 'active' : ''}`}>
                          <Link onClick={() => whichroute('Contact')} component={rlink} to="/contact" className={trigger ? 'scrolled' : ''}>Contact us</Link>
                        </li>
                        <li className="aaryan-menu-right">
                          <TravelRequest />
                        </li>
                      </List>
                    </Grid >
                  </Grid>
                </Grid>
              )}
            </Toolbar>
          </AppBar>
        </Box>

        {/* Snackbar for logout message */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message="Logout successful"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      </ThemeProvider>
    </React.Fragment>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
