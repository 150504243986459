import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Virtual, Navigation, FreeMode, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from '@mui/material/Skeleton';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { CardActionArea } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { alpha, Container } from '@mui/system';

import { GlobalTheme } from '../../theme';
import DestinationStyle from './assets/scss/destination.module.scss';

const BASE_MEDIA_URL = 'https://aaryanholidays.in/express/uploads/';

const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};

const Carousel = () => {
    const [destinationData, setDestinationData] = useState([]);
    const videoRefs = useRef([]);
    const [swiperRef, setSwiperRef] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const handleMouseEnter = (index) => {
        const videoElement = videoRefs.current[index];
        if (videoElement && videoElement.paused) {
            videoElement.play().catch(error => console.error('Error playing video:', error));
        }
    };

    const handleMouseLeave = (index) => {
        const videoElement = videoRefs.current[index];
        if (videoElement && videoElement.tagName === 'VIDEO' && !videoElement.paused) {
            videoElement.pause();
            videoElement.currentTime = 0;
        }
    };

    const handleCardClick = (id) => {
        navigate(`/tour-details/4/${id}`);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const destinationsResponse = await fetch('https://aaryanholidays.in/frontend/alldestinations');
                const destinationsData = await destinationsResponse.json();
                const promotedData = destinationsData.filter(destination => destination.promote === 1);
                setDestinationData(promotedData);

                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            {isLoading ? (
                <>
                    <Grid item xs={12} sx={{ overflow: 'hidden' }}>
                        <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 4 }}>
                            <Skeleton variant="rectangular" animation='wave' width={200} sx={{ height: 20, mb: 3 }} />
                            <Skeleton variant="rectangular" animation='wave' width={200} sx={{ height: 10, mb: 1 }} />
                            <Skeleton variant="rectangular" animation='wave' width={400} sx={{ height: 10, mb: 1 }} />
                            <Skeleton variant="rectangular" animation='wave' width={500} sx={{ height: 10, mb: 1 }} />
                        </Box>

                        <Stack direction='row' justifyContent='space-between' flexWrap="wrap" gap={1} sx={{ overflow: 'hidden' }}>
                            {Array.from({ length: 6 }).map((_, index) => (
                                <Skeleton
                                    key={index}
                                    variant="rectangular"
                                    animation="wave"
                                    width={258}
                                    sx={{ height: 348, borderRadius: 2 }}
                                />
                            ))}
                        </Stack>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={12} lg={12} sx={{ overflow: 'hidden' }}>
                        <Typography id="destinationHeading" component='h2' variant='h2' sx={{ mb: 2, fontWeight: 600 }}>Trending</Typography>
                        <Typography component='p' marginBottom={4}>Explore our customer favorites list of the top-selling destinations.</Typography>
                        <Swiper
                            keyboard={{ enabled: true }}
                            modules={[Virtual, Navigation, FreeMode, Keyboard]}
                            onSwiper={setSwiperRef}
                            slidesPerView={3}
                            centeredSlides={false}
                            spaceBetween={50}
                            pagination
                            navigation={{
                                nextEl: '.custom-swiper-button-next',
                                prevEl: '.custom-swiper-button-prev',
                            }}

                            grabCursor={true}
                            // freeMode={true}
                            // loop={true}
                            //  slidesOffsetAfter={10}

                            className={DestinationStyle.swiperContainer}
                            breakpoints={{
                                0: { slidesPerView: 1, spaceBetween: 10 },
                                600: { slidesPerView: 2, spaceBetween: 20 },
                                900: { slidesPerView: 4, spaceBetween: 40 },
                                1200: { slidesPerView: 5, spaceBetween: 50 },
                            }}
                            style={{
                                width: '100%',
                                position: 'relative'
                            }}
                        >
                            {destinationData.map((tour, index) => (
                                <SwiperSlide
                                    key={index}
                                    virtualIndex={index}
                                    className={DestinationStyle.swiperSlideCustom}
                                >
                                    <Card
                                        key={index}
                                        sx={{
                                            position: 'relative',
                                            borderRadius: 2,
                                            width: { xs: '100%', sm: 258 },
                                            height: 348,
                                            objectFit: 'cover',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        <CardActionArea
                                            id={'destination' + tour.id}
                                            onClick={() => handleCardClick(tour.id)}
                                            onMouseEnter={() => handleMouseEnter(index)}
                                            onMouseLeave={() => handleMouseLeave(index)}
                                            sx={{ height: 'inherit' }}
                                        >
                                            {tour.type === 'video' ? (
                                                <CardMedia
                                                    id={`destinationThumbsVid${index}`}
                                                    component="video"
                                                    src={`${BASE_MEDIA_URL}${tour.src}`}
                                                    loop={true}
                                                    muted
                                                    playsInline
                                                    ref={(el) => (videoRefs.current[index] = el)}
                                                >
                                                    <source src={`${BASE_MEDIA_URL}${tour.src}`} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </CardMedia>
                                            ) : (
                                                <CardMedia
                                                    id={`destinationThumbsImg${index}`}
                                                    component="img"
                                                    src={`${BASE_MEDIA_URL}${tour.src}`}
                                                    alt={tour.alt}
                                                    loading='lazy'
                                                />
                                            )}
                                            <CardContent
                                                sx={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    left: 0,
                                                    textAlign: 'left',
                                                    height: '100%',
                                                    width: '100%',
                                                    pt: 35,
                                                    transition: 'all .4s ease-in',
                                                    "&:hover": {
                                                        pt: 23,
                                                        backgroundColor: alpha(GlobalTheme.palette.common.tertiary, 0.5)
                                                    }
                                                }}
                                            >
                                                <Typography variant='h3' component='h3' sx={{ color: GlobalTheme.palette.common.white, mb: 2 }} gutterBottom>
                                                    <span dangerouslySetInnerHTML={{ __html: tour.thumbnail_heading }} />
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>

                                    </Card>
                                    <Typography variant="subtitle1" marginTop={2} textAlign='left' color='common.bodyTxt' fontWeight={500}>
                                        {tour.tour_days}
                                    </Typography>

                                    <Typography variant="subtitle2" sx={{ textAlign: 'left' }} >
                                        {truncateText(tour.thumbnail_desc, 90)}
                                    </Typography>
                                </SwiperSlide>
                            ))}


                            <IconButton
                                className="custom-swiper-button-prev"
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: 10,
                                    transform: 'translateY(-50%)',
                                    zIndex: 10,
                                    color: GlobalTheme.palette.primary.main,
                                    backgroundColor: GlobalTheme.palette.common.white,
                                    transition: 'transform .3s ease-in',

                                    "&:hover, &:focus-within": {
                                        boxShadow: GlobalTheme.shadows[5],
                                        backgroundColor: GlobalTheme.palette.common.white,
                                        transform: 'translateX(-5px) translateY(-50%)',

                                        "& .MuiSvgIcon-root": {
                                            transform: 'translateX(-4px)',
                                            transition: 'transform 0.3s ease',
                                        },
                                    }
                                }}
                            >
                                <ArrowBackIcon sx={{ fontSize: GlobalTheme.typography.h1.fontSize }} />
                            </IconButton>



                            <IconButton
                                className="custom-swiper-button-next"
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: 10,
                                    transform: 'translateY(-50%)',
                                    zIndex: 10,
                                    color: GlobalTheme.palette.primary.main,
                                    backgroundColor: GlobalTheme.palette.common.white,
                                    transition: 'transform .3s ease-in',

                                    "&:hover, &:focus-visible": {
                                        boxShadow: GlobalTheme.shadows[5],
                                        backgroundColor: GlobalTheme.palette.common.white,
                                        transform: 'translateX(4px) translateY(-50%)',

                                        "& .MuiSvgIcon-root": {
                                            transform: 'translateX(4px)',
                                            transition: 'transform 0.3s ease',
                                        },
                                    }
                                }}
                            >
                                <ArrowForwardIcon sx={{ fontSize: GlobalTheme.typography.h1.fontSize }} />
                            </IconButton>

                        </Swiper>
                    </Grid>
                </>
            )}
        </Grid >
    );
};

export default Carousel;
