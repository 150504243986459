import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { GlobalTheme } from './../../theme';
import { Container, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TablePagination from '@mui/material/TablePagination';
import { alpha } from '@mui/system';

import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid';

import DynamicMantle from './../../components/mantles/DynamicMantle';
import GoogleAnalytics from '../../components/gAnalytics/gAnalytics';

const BASE_MEDIA_URL = 'https://aaryanholidays.in/express/uploads/';

const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};

export default function TourListing() {
    const { refer_other_id } = useParams();
    const navigate = useNavigate();
    const [tourListingData, setTourListingData] = useState([]);
    const [tourTypeData, setTourTypeData] = useState({ heading: '', desc_txt: '', banner_type: '', banner_src: '', banner_alt: '', banner_title: '', banner_description: '' });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const videoRefs = useRef([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginatedData = tourListingData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleMouseEnter = (index) => {
        const videoElement = videoRefs.current[index];
        if (videoElement && videoElement.paused) {
            videoElement.play().catch(error => console.error('Error playing video:', error));
        }
    };

    const handleMouseLeave = (index) => {
        const videoElement = videoRefs.current[index];
        if (videoElement && !videoElement.paused) {
            videoElement.pause();
            videoElement.currentTime = 0;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [tourResponse, tourTypeResponse] = await Promise.all([
                    fetch(`https://aaryanholidays.in/frontend/destinations?tour_type_id=${refer_other_id}`),
                    fetch(`https://aaryanholidays.in/frontend/tour-types/${refer_other_id}`)
                ]);
                const tourData = await tourResponse.json();
                const tourTypeData = await tourTypeResponse.json();

                setTourListingData(tourData);
                setTourTypeData(tourTypeData);
                setPage(0);
                console.log(tourData);
                console.log(tourTypeData);

            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        }
        fetchData();
    }, [refer_other_id]);

    const handleCardClick = (id) => {
        navigate(`/tour-details/4/${id}`)
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{tourTypeData?.banner_title ? `${tourTypeData?.banner_title} - Aaryan Holidays` : ''}</title>
                <meta name="description" content={tourTypeData?.banner_description || ""} />
                <meta name="keywords" content={tourTypeData?.keyword || ""} />
                <meta property="og:title" content={tourTypeData?.banner_title || ""} />
                <meta property="og:description" content={tourTypeData?.banner_description || ""} />
                <meta property="og:url" content={`https://aaryanholidays.com/tour-type/4/${refer_other_id}`} />
                <meta property="og:image" content={`${BASE_MEDIA_URL}${tourTypeData?.banner_src}` || ""} />


            </Helmet>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />
                <DynamicMantle
                    banner_type={tourTypeData.banner_type}
                    banner_src={`${BASE_MEDIA_URL}${tourTypeData.banner_src}`}
                    banner_alt={tourTypeData.banner_alt}
                    banner_title={tourTypeData.banner_title}
                    banner_description={tourTypeData.banner_description}
                />

                <Container fixed sx={{ py: 5 }}>
                    <Grid textAlign='center'>
                        <Typography id="destinationHeading" component='h2' variant='h2' sx={{ mb: 2, display: 'block', width: '100%' }}>
                            {tourTypeData.heading}
                        </Typography>
                        {/* <Typography component='p'>
                            {tourTypeData.desc_txt}
                        </Typography> */}
                    </Grid>

                    <Grid container spacing={4} id='tourListing' sx={{ mt: 2 }}>
                        {paginatedData.map((tour, index) => (
                            <Grid
                                item
                                id={`tourListing${tour.id}`}
                                key={tour.id}
                                xs={6}
                                sm={4}
                                md={4}
                                lg={3}
                                sx={{ display: 'inline-flex' }}
                            >
                                <Card
                                    sx={{
                                        position: 'relative',
                                        borderRadius: 2,
                                        height: { md: 348 },
                                        overflow: 'hidden'
                                    }}>
                                    <CardActionArea
                                        id={'destinationListing' + tour.id}
                                        onClick={() => handleCardClick(tour.id)}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={() => handleMouseLeave(index)}
                                        sx={{ height: 'inherit' }}
                                    >

                                        {tour.type === 'video' ? (
                                            <CardMedia
                                                id={`destinationThumbsVid${index}`}
                                                component="video"
                                                src={`${BASE_MEDIA_URL}${tour.src}`}
                                                loop
                                                muted
                                                playsInline
                                                ref={(el) => (videoRefs.current[index] = el)}
                                                sx={{ height: 'inherit' }}
                                            >
                                                <source src={`${BASE_MEDIA_URL}${tour.src}`} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </CardMedia>
                                        ) : (
                                            <CardMedia
                                                id={`destinationThumbsImg${index}`}
                                                component="img"
                                                src={`${BASE_MEDIA_URL}${tour.src}`}
                                                alt={tour.alt}
                                                loading='lazy'
                                            />
                                        )}

                                        <CardContent
                                            component='span'
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                textAlign: 'left',
                                                height: { md: '100%' },
                                                width: '100%',
                                                pt: { md: 35 },
                                                transition: 'all .4s ease-in',
                                                "&:hover": {
                                                    pt: { xs: 17.5, md: 23 },
                                                    backgroundColor: alpha(GlobalTheme.palette.common.tertiary, 0.5)
                                                }
                                            }}>

                                            <Typography variant='h3' component='h3' sx={{ color: GlobalTheme.palette.common.white }} gutterBottom>
                                                <span dangerouslySetInnerHTML={{ __html: tour.thumbnail_heading }} />
                                            </Typography>
                                            <Typography variant="subtitle1" sx={{ color: GlobalTheme.palette.common.white }}>
                                                {tour.tour_days}
                                            </Typography>
{/* 
                                            <Typography variant="subtitle2" sx={{ color: GlobalTheme.palette.common.white }}>
                                                {truncateText(tour.thumbnail_desc, 80)}
                                            </Typography> */}
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}

                    </Grid>
                    <Grid sx={{ mt: 3, display: 'block', px: 0 }}>
                        <TablePagination
                            component="div"
                            count={tourListingData.length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[8, 16, 24]}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>

                </Container>
            </ThemeProvider>
        </React.Fragment>
    )
}
