import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

import DestinationCarousel from './destination';
import CoreCompetency from './coreCompetency';
import Discovery from './discovery';
import Hotels from '../../components/hotels/hotels-home';
import PartnersExclusive from './partnersExclusive';

import HomeMantle from './../../components/mantles/mantle';

import { GlobalTheme } from './../../theme';
import Testimonial from './../../components/testimonials/testimonials';

import BgImg from './assets/images/bg.jpg';
import Members from './../../components/members/members';


export default function Home() {
    const theme = useTheme();

    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />
                <HomeMantle home={1} />

                <Grid component='section' aria-labelledby='destinationHeading'
                    sx={{
                        textAlign: 'center',
                        py: 7,
                        backgroundImage: `url(${BgImg})`,
                        backgroundAttachment: 'fixed',
                    }}>
                    <Container maxWidth="xxl">
                        <DestinationCarousel />
                    </Container>
                </Grid>

                <Container maxWidth="xxl"
                    sx={{
                        [theme.breakpoints.down('sm')]: {
                            px: 0,
                        },
                        [theme.breakpoints.up('sm')]: {
                            px: 0,
                        }
                    }}>
                    <Grid component='section' aria-labelledby='vacationHeading' sx={{ textAlign: 'center', backgroundColor: GlobalTheme.palette.primary.main, color: GlobalTheme.palette.common.white }}>
                        <Container sx={{ py: 7 }}>
                            <Typography variant="h2" component="h2" sx={{ color: GlobalTheme.palette.common.white, mb: 2 }} id="vacationHeading">
                                Make your vacation unforgettable<br />
                                with Aaryan leisure's & holidays
                            </Typography>
                            <Typography variant='' component='p' sx={{
                                md: { px: 20 },
                                textWrap: 'balance', wordBreak: 'break-word'

                            }}>
                                Whether you're after a beach escape, mountain adventure, or cultural city tour, Aaryan Leisure & Holidays offers tailored travel packages for lasting memories.<br /><br />
                                Enjoy top-notch service, exclusive deals, and personalized itineraries for a perfect mix of comfort, excitement, and relaxation. Make your next vacation unforgettable!
                            </Typography>
                        </Container>

                        <Container maxWidth="xxl" sx={{ backgroundColor: GlobalTheme.palette.warning.light, pt: 9, pb: 3 }}>
                            <Container>
                                <Discovery />
                            </Container>
                        </Container>
                    </Grid>
                    <Hotels />
                    {/* <Container maxWidth="lg" sx={{ pt: 6, pb: 3, textAlign: 'center', py: 5 }} aria-labelledby="popularHotelsHeading" component="section">
                        



                    </Container> */}

                    <Container maxWidth="xxl" sx={{ pt: 6, pb: 3, textAlign: 'center', backgroundColor: GlobalTheme.palette.common.grayLight }} aria-labelledby="partnersHeading" component="section">
                        <Container fixed>
                            <Typography id="partnersHeading" variant='h2' component='h2' sx={{ mb: 2, textAlign: 'left' }}>partners exclusive insight</Typography>
                            <PartnersExclusive />
                        </Container>
                    </Container>

                    <Container fixed sx={{ pt: 6, pb: 3, textAlign: 'center', backgroundColor: GlobalTheme.palette.common.white }} aria-labelledby="competencyHeading" component="section">
                        <Typography id="competencyHeading" variant='h2' component='h2' sx={{ mb: 2 }}>CORE COMPETENCY</Typography>
                        <Typography variant='subtitle2' component='p' sx={{ mb: 4 }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor</Typography>

                        <CoreCompetency />
                    </Container>

                    <Container maxWidth="xxl" sx={{ py: 6, backgroundColor: GlobalTheme.palette.warning.light }} aria-labelledby="testimonialHeading" component="section">

                        <Typography id="testimonialHeading" variant='h2' component='h2' sx={{ mb: 2, textAlign: 'center' }}>Our Valuable Customers<br />Awesome Feedback</Typography>
                        <Testimonial />
                    </Container>

                    <Container maxWidth="xxl" sx={{ pt: 6, pb: 3, backgroundColor: GlobalTheme.palette.common.grayLight }} aria-labelledby="membersHeading" component="section">
                        <Members />
                    </Container>

                </Container>
            </ThemeProvider>
        </React.Fragment >
    )

}