import React, { useEffect, useState, useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import { alpha } from '@mui/material';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { ThemeProvider } from '@mui/material/styles';
import { GlobalTheme } from '../../theme';

import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import './assets/scss/style.scss';

const timelineEvents = [
    { time: "2002: The Beginning", icon: <DateRangeOutlinedIcon />, main: "What started as a dream soon became a reality when Aaryan Holidays was founded with a vision to provide travelers with curated, unforgettable experiences. From humble beginnings as a startup, we set out to redefine travel with a personal touch." },
    { time: "2004: A Leap Forward", icon: <DateRangeOutlinedIcon />, main: "Two years later, Aaryan Holidays took a bold step forward, transforming into Aaryan Leisure & Holidays Pvt. Ltd. This marked a significant milestone in our journey, expanding our services beyond expectations and becoming a trusted name in the industry." },
    { time: "2011: Expanding Horizons", icon: <DateRangeOutlinedIcon />, main: "As the company’s reputation grew, so did our operations. In 2011, we broadened our reach by establishing branches in Amritsar and Bhubaneswar, while our Kolkata headquarters continued to anchor our vision. This expansion allowed us to bring our personalized travel services to more regions, offering unparalleled travel experiences across India." },
    { time: "2020: The Covid Effect", icon: <DateRangeOutlinedIcon />, main: "In 2020 as the world came to a standstill due to the Covid-19 pandemic. We endured a severe decline in business, but what set us apart was our unwavering determination. Even in the darkest times, our team stood strong, united by the belief that every setback is a setup for a comeback." },
    { time: "2022: A Powerful Comeback", icon: <DateRangeOutlinedIcon />, main: "2022 marked a turning point in our journey. After navigating the challenges of the pandemic, we made a remarkable recovery, achieving our highest sales to date. This monumental success propelled us into the Top 100 Travel Companies in India, solidifying Aaryan Leisure & Holidays as a leader in the travel industry." },
];

export default function CustomizedTimeline() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const timelineRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset + window.innerHeight;
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const isInViewport = (element) => {
        if (!element) return false;
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />
                <Timeline position="alternate" ref={timelineRef} >
                    {timelineEvents.map((event, index) => (
                        <TimelineItem key={index} ref={(el) => {
                            if (el && scrollPosition > el.getBoundingClientRect().top + window.scrollY) {
                                el.classList.add('in-viewport');
                            }
                        }}>
                            <TimelineSeparator sx={{ display: { xs: 'none', sm: 'flex' } }}>
                                <TimelineConnector
                                    className={`timeline-connector ${scrollPosition > (index + 1) * 100 ? 'timeline-connector-filled' : ''}`}
                                />
                                <TimelineDot className={`timeline-dot ${scrollPosition > (index + 1) * 100 ? 'timeline-dot-filled' : ''}`}>
                                    {event.icon}
                                </TimelineDot>
                                <TimelineConnector
                                    className={`timeline-connector ${scrollPosition > (index + 1) * 100 ? 'timeline-connector-filled' : ''}`}
                                />
                            </TimelineSeparator>

                            <TimelineContent sx={{ py: '12px', px: 2, borderRadius: 3 }} >
                                <Paper elevation={24} sx={{
                                    p: 4,
                                    boxShadow: `0 0 20px 4px ${alpha(GlobalTheme.palette.common.black, 0.1)}`
                                }}>
                                    <Typography variant="h2" component="h2" gutterBottom sx={{ fontWeight: 600, }}>
                                        {event.time}
                                    </Typography>
                                    <Typography color='common.tertiary'>{event.main}</Typography>

                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </ThemeProvider>
        </React.Fragment>
    );
}
