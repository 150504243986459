// import React, { useState } from 'react';
import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, alpha } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { Stepper, Step, StepLabel, InputLabel } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { GlobalTheme } from './../../theme';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert'; // Import MuiAlert
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useMediaQuery } from '@mui/material';

import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';

import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import Stack from '@mui/material/Stack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import Logo from './assets/images/logo_full.svg';
import axios from 'axios';
import BgImage from './assets/images/bg.jpg';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
// Alert component for Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function FullScreenDialog({ open, onClose, destination }) {
    const isMobile = useMediaQuery(GlobalTheme.breakpoints.down('md'));
    const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('md'));
    const [contentHeight, setContentHeight] = useState(0);
    const [adultCount, setAdultCount] = useState(1); // Minimum 1 adult
    const [childCount, setChildCount] = useState(0);
    const [childAges, setChildAges] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [travelDate, setTravelDate] = useState(null);
    const [travelEndDate, setTravelEndDate] = useState(null);
    const [duration, setDuration] = useState('');
    const [formData, setFormData] = useState({
        destination: destination || '',  // Set initial value from prop
        fullName: '',
        countryCode: '',
        phone: '',
        email: '',
        trvlpreferences: '',
        actpreferences: ''
    });

    useEffect(() => {
        // Update the formData destination when the prop changes
        setFormData(prevState => ({
            ...prevState,
            destination: destination || ''
        }));
    }, [destination]);
    // Validation states
    const [errors, setErrors] = useState({
        fullName: '',
        email: '',
        countryCode: '',
        phone: ''
    });



    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const steps = [
        { label: 'Destination Details\nOverview' },
        { label: 'Select your \ntravel dates' },
        { label: 'Preferences and\nInterests' },
        { label: '. Travelers’ \ninformation' },
        { label: 'Review and\nConfirmation' }
    ];

    const travelOptions = [
        { option: 'Luxury experience' },
        { option: 'Relaxation' },
        { option: 'Adventure & Nature' },
        { option: 'Cultural immersion' },
        { option: 'Family & Kids' },
        { option: 'Romantic' },
        { option: 'Off Beat destinations' },
        { option: 'Food/Drinks' },
        { option: 'Mixed travel' }
    ];



    const travelActivity = [
        { option: 'Sightseeing and Exploration' },
        { option: 'Culinary experiences' },
        { option: 'Shopping' },
        { option: 'Nightlife and entertainment' },
        { option: 'Adventure & Safari' },
        { option: 'Theme parks & kid’s activities' },
        { option: 'Beach & Water sports' },
        { option: 'Give me something new' }
    ];



    const validateStep = (step) => {
        let isValid = true;
        const newErrors = { ...errors };

        switch (step) {
            case 0:
                if (!formData.destination.trim()) {
                    newErrors.destination = 'Destination is required';
                    isValid = false;
                }
                else {
                    newErrors.destination = '';

                }
                break;
            case 1:
                if (!travelDate) {
                    newErrors.travelDate = (
                        <Box component='span' sx={{ color: GlobalTheme.palette.error.main, fontSize: 12, display: 'block' }}>
                            Travel date is required
                        </Box>
                    );
                    isValid = false;
                }
                else {
                    newErrors.travelDate = '';

                }
                if (!travelEndDate) {
                    newErrors.travelEndDate = 'Travel end date is required';
                    isValid = false;
                } else {
                    newErrors.travelEndDate = '';
                }
                if (!duration.trim()) {
                    newErrors.duration = 'Duration is required';
                    isValid = false;
                }
                else {
                    newErrors.duration = '';

                }
                break;
            case 3:
                if (!formData.fullName.trim()) {
                    newErrors.fullName = 'Full name is required';
                    isValid = false;
                } else if (/[^a-zA-Z\s]/.test(formData.fullName)) { // Check for special characters or numbers
                    newErrors.fullName = 'Name cannot contain numbers or special characters';
                    isValid = false;
                } else {
                    newErrors.fullName = '';
                }
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!formData.email.trim()) {
                    newErrors.email = 'Email is required';
                    isValid = false;
                } else if (!emailRegex.test(formData.email)) {
                    newErrors.email = 'Invalid email format';
                    isValid = false;
                }
                else {
                    newErrors.email = '';

                }
                const phoneRegex = /^\+?[1-9]\d{1,14}$/; // International phone number regex
                if (!formData.phone.trim()) {
                    newErrors.phone = 'Phone number is required';
                    isValid = false;
                } else if (!phoneRegex.test(formData.phone)) {
                    newErrors.phone = 'Invalid phone number';
                    isValid = false;
                } else {
                    newErrors.phone = '';
                }
                break;
            default:
                break;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleNext = () => {
        if (validateStep(activeStep)) {
            setActiveStep((prevStep) => prevStep + 1);
        }
    };


    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleAdultChange = (type) => {
        setAdultCount((prevCount) => (type === 'add' ? prevCount + 1 : prevCount > 1 ? prevCount - 1 : 1));
    };

    const handleChildChange = (type) => {
        if (type === 'add') {
            setChildCount((prevCount) => prevCount + 1);
            //   setChildAges([...childAges, '']);
        } else if (type === 'remove' && childCount > 0) {
            setChildCount((prevCount) => prevCount - 1);
            //   setChildAges((prevAges) => prevAges.slice(0, -1)); // Remove last age input
        }
    };

    const handleAgeChange = (index, age) => {
        const updatedAges = [...childAges];
        updatedAges[index] = age;
        setChildAges(updatedAges);
    };

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };
    const handleltrvlChange = (event, newValue) => {
        setFormData({
            ...formData,
            trvlpreferences: newValue ? newValue.option : ''  // Handle null case
        });
    };

    const handleltrvlactChange = (event, newValue) => {
        setFormData({
            ...formData,
            actpreferences: newValue ? newValue.option : ''  // Handle null case
        });
    };
    const handlePhoneChange = (event, newValue) => {
        // Append selected country code to input or handle change if typing directly
        setFormData({
            ...formData,
            phone: newValue ? `+${newValue.phone}` : event.target.value
        });
    };

    const handleAutocompleteChange = (event, newValue) => {
        // Set country code from Autocomplete selection and retain any phone digits already typed
        const phoneDigits = formData.phone.replace(/^\+\d+\s*/, ''); // Remove existing country code
        setFormData({
            ...formData,
            phone: newValue ? `+${newValue.phone} ${phoneDigits}` : '' // Keep existing phone digits if any
        });
    };
    const listData = [
        { label: 'Travellers:', value: `Adults: ${adultCount}, Children: ${childCount}, Ages: ${childAges.join(', ')}` },
        { label: 'Destination:', value: formData.destination },
        // { label: 'Travel Date:', value: travelDate?.format('YYYY-MM-DD') },
        { label: 'Travel Date:', value: `${travelDate?.format('YYYY-MM-DD')} to ${travelEndDate?.format('YYYY-MM-DD')}` }, // Concatenating dates
        { label: 'Trip Duration:', value: `${duration} days` },
        { label: 'Full Name:', value: formData.fullName },
        { label: 'Phone:', value: `${formData.countryCode} ${formData.phone}`.trim() },
        { label: 'Email:', value: formData.email },
        { label: 'Travel Preferences:', value: formData.trvlpreferences },
        { label: 'Activity Preferences:', value: formData.actpreferences }
    ];

    // const handleSubmit = () => {
    //     // Submit logic here
    //     console.log('Form submitted:', { formData, adultCount, childCount, childAges, travelDate, duration });
    // };

    const closeSubmission = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    };

    const handleSubmit = async () => {
        if (!validateStep(4)) return;
        try {
            const response = await axios.post('https://aaryanholidays.in/backend/submit-travel-form', {
                destination: formData.destination,
                travellers: `Adults: ${adultCount}, Children: ${childCount}, Ages: ${childAges.join(', ')}`,
                // travel_date: travelDate?.format('YYYY-MM-DD'),
                travel_date: `${travelDate?.format('YYYY-MM-DD')} to ${travelEndDate?.format('YYYY-MM-DD')}`, // Concatenating start and end dates

                trip_duration: duration,
                full_name: formData.fullName,
                phone: `${formData.countryCode} ${formData.phone}`.trim(),
                email: formData.email,
                preferences: `Travel : ${formData.trvlpreferences}, Activity: ${formData.actpreferences},`
            });
            // Display response message in the snackbar
            setSnackbarMessage(response.data.message);
            setSnackbarOpen(true);
            setSnackbarSeverity('success'); // Set snackbar to success

            // Close the dialog after 10 seconds
            setTimeout(() => {
                handleCloseForm();
            }, 100);
        } catch (error) {
            console.error('Error submitting form:', error);
            setSnackbarMessage('Error submitting form:');
            setSnackbarOpen(true);
            setSnackbarSeverity('error'); // Set snackbar to success
            alert('Error submitting form. Please try again.');
        }
    };
    const stepImages = [
        {
            punchLine: 'Ready to discover new horizons?',
            subline: 'Ready to explore the world in a whole new way? Let’s dive into new places, meet new faces, and make memories that stick. Adventure awaits!',
            src: 'https://as2.ftcdn.net/v2/jpg/05/73/29/09/1000_F_573290990_xwAe8yTs5ZFeQtgLfkwly9j78ZM3qZs8.jpg'
        },
        {
            punchLine: 'Ready to discover new horizons?',
            subline: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            src: 'https://as1.ftcdn.net/v2/jpg/05/65/53/38/1000_F_565533854_9kUMcL5Au8dNHIzXP0lLmUBhyL6X3IjQ.jpg'
        },
        {
            punchLine: 'Ready to discover new horizons?',
            subline: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            src: 'https://as2.ftcdn.net/v2/jpg/05/73/29/09/1000_F_573290990_xwAe8yTs5ZFeQtgLfkwly9j78ZM3qZs8.jpg'
        },
        {
            punchLine: 'Ready to discover new horizons?',
            subline: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            src: 'https://as1.ftcdn.net/v2/jpg/05/65/53/38/1000_F_565533854_9kUMcL5Au8dNHIzXP0lLmUBhyL6X3IjQ.jpg'
        },
        {
            punchLine: 'Ready to discover new horizons?',
            subline: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            src: 'https://as2.ftcdn.net/v2/jpg/05/73/29/09/1000_F_573290990_xwAe8yTs5ZFeQtgLfkwly9j78ZM3qZs8.jpg'
        },
    ];
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    const handleCloseForm = () => {
        // Reset form data to default values
        setAdultCount(0);
        setChildCount(0);
        setChildAges([]);
        setActiveStep(0);
        setTravelDate(null);
        setTravelEndDate(null);
        setDuration('');
        setFormData({
            destination: '',
            fullName: '',
            phone: '',
            email: '',
            trvlpreferences: '',
            actapreferences: ''
        });
        renderStepContentnew(0);
        onClose();
    };

    const countries = [
        { code: 'AD', label: 'Andorra', phone: '376' },
        {
            code: 'AE',
            label: 'United Arab Emirates',
            phone: '971',
        },
        { code: 'AF', label: 'Afghanistan', phone: '93' },
        {
            code: 'AG',
            label: 'Antigua and Barbuda',
            phone: '1-268',
        },
        { code: 'AI', label: 'Anguilla', phone: '1-264' },
        { code: 'AL', label: 'Albania', phone: '355' },
        { code: 'AM', label: 'Armenia', phone: '374' },
        { code: 'AO', label: 'Angola', phone: '244' },
        { code: 'AQ', label: 'Antarctica', phone: '672' },
        { code: 'AR', label: 'Argentina', phone: '54' },
        { code: 'AS', label: 'American Samoa', phone: '1-684' },
        { code: 'AT', label: 'Austria', phone: '43' },
        {
            code: 'AU',
            label: 'Australia',
            phone: '61',
            suggested: true,
        },
        { code: 'AW', label: 'Aruba', phone: '297' },
        { code: 'AX', label: 'Alland Islands', phone: '358' },
        { code: 'AZ', label: 'Azerbaijan', phone: '994' },
        {
            code: 'BA',
            label: 'Bosnia and Herzegovina',
            phone: '387',
        },
        { code: 'BB', label: 'Barbados', phone: '1-246' },
        { code: 'BD', label: 'Bangladesh', phone: '880' },
        { code: 'BE', label: 'Belgium', phone: '32' },
        { code: 'BF', label: 'Burkina Faso', phone: '226' },
        { code: 'BG', label: 'Bulgaria', phone: '359' },
        { code: 'BH', label: 'Bahrain', phone: '973' },
        { code: 'BI', label: 'Burundi', phone: '257' },
        { code: 'BJ', label: 'Benin', phone: '229' },
        { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
        { code: 'BM', label: 'Bermuda', phone: '1-441' },
        { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
        { code: 'BO', label: 'Bolivia', phone: '591' },
        { code: 'BR', label: 'Brazil', phone: '55' },
        { code: 'BS', label: 'Bahamas', phone: '1-242' },
        { code: 'BT', label: 'Bhutan', phone: '975' },
        { code: 'BV', label: 'Bouvet Island', phone: '47' },
        { code: 'BW', label: 'Botswana', phone: '267' },
        { code: 'BY', label: 'Belarus', phone: '375' },
        { code: 'BZ', label: 'Belize', phone: '501' },
        {
            code: 'CA',
            label: 'Canada',
            phone: '1',
            suggested: true,
        },
        {
            code: 'CC',
            label: 'Cocos (Keeling) Islands',
            phone: '61',
        },
        {
            code: 'CD',
            label: 'Congo, Democratic Republic of the',
            phone: '243',
        },
        {
            code: 'CF',
            label: 'Central African Republic',
            phone: '236',
        },
        {
            code: 'CG',
            label: 'Congo, Republic of the',
            phone: '242',
        },
        { code: 'CH', label: 'Switzerland', phone: '41' },
        { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
        { code: 'CK', label: 'Cook Islands', phone: '682' },
        { code: 'CL', label: 'Chile', phone: '56' },
        { code: 'CM', label: 'Cameroon', phone: '237' },
        { code: 'CN', label: 'China', phone: '86' },
        { code: 'CO', label: 'Colombia', phone: '57' },
        { code: 'CR', label: 'Costa Rica', phone: '506' },
        { code: 'CU', label: 'Cuba', phone: '53' },
        { code: 'CV', label: 'Cape Verde', phone: '238' },
        { code: 'CW', label: 'Curacao', phone: '599' },
        { code: 'CX', label: 'Christmas Island', phone: '61' },
        { code: 'CY', label: 'Cyprus', phone: '357' },
        { code: 'CZ', label: 'Czech Republic', phone: '420' },
        {
            code: 'DE',
            label: 'Germany',
            phone: '49',
            suggested: true,
        },
        { code: 'DJ', label: 'Djibouti', phone: '253' },
        { code: 'DK', label: 'Denmark', phone: '45' },
        { code: 'DM', label: 'Dominica', phone: '1-767' },
        {
            code: 'DO',
            label: 'Dominican Republic',
            phone: '1-809',
        },
        { code: 'DZ', label: 'Algeria', phone: '213' },
        { code: 'EC', label: 'Ecuador', phone: '593' },
        { code: 'EE', label: 'Estonia', phone: '372' },
        { code: 'EG', label: 'Egypt', phone: '20' },
        { code: 'EH', label: 'Western Sahara', phone: '212' },
        { code: 'ER', label: 'Eritrea', phone: '291' },
        { code: 'ES', label: 'Spain', phone: '34' },
        { code: 'ET', label: 'Ethiopia', phone: '251' },
        { code: 'FI', label: 'Finland', phone: '358' },
        { code: 'FJ', label: 'Fiji', phone: '679' },
        {
            code: 'FK',
            label: 'Falkland Islands (Malvinas)',
            phone: '500',
        },
        {
            code: 'FM',
            label: 'Micronesia, Federated States of',
            phone: '691',
        },
        { code: 'FO', label: 'Faroe Islands', phone: '298' },
        {
            code: 'FR',
            label: 'France',
            phone: '33',
            suggested: true,
        },
        { code: 'GA', label: 'Gabon', phone: '241' },
        { code: 'GB', label: 'United Kingdom', phone: '44' },
        { code: 'GD', label: 'Grenada', phone: '1-473' },
        { code: 'GE', label: 'Georgia', phone: '995' },
        { code: 'GF', label: 'French Guiana', phone: '594' },
        { code: 'GG', label: 'Guernsey', phone: '44' },
        { code: 'GH', label: 'Ghana', phone: '233' },
        { code: 'GI', label: 'Gibraltar', phone: '350' },
        { code: 'GL', label: 'Greenland', phone: '299' },
        { code: 'GM', label: 'Gambia', phone: '220' },
        { code: 'GN', label: 'Guinea', phone: '224' },
        { code: 'GP', label: 'Guadeloupe', phone: '590' },
        { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
        { code: 'GR', label: 'Greece', phone: '30' },
        {
            code: 'GS',
            label: 'South Georgia and the South Sandwich Islands',
            phone: '500',
        },
        { code: 'GT', label: 'Guatemala', phone: '502' },
        { code: 'GU', label: 'Guam', phone: '1-671' },
        { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
        { code: 'GY', label: 'Guyana', phone: '592' },
        { code: 'HK', label: 'Hong Kong', phone: '852' },
        {
            code: 'HM',
            label: 'Heard Island and McDonald Islands',
            phone: '672',
        },
        { code: 'HN', label: 'Honduras', phone: '504' },
        { code: 'HR', label: 'Croatia', phone: '385' },
        { code: 'HT', label: 'Haiti', phone: '509' },
        { code: 'HU', label: 'Hungary', phone: '36' },
        { code: 'ID', label: 'Indonesia', phone: '62' },
        { code: 'IE', label: 'Ireland', phone: '353' },
        { code: 'IL', label: 'Israel', phone: '972' },
        { code: 'IM', label: 'Isle of Man', phone: '44' },
        { code: 'IN', label: 'India', phone: '91' },
        {
            code: 'IO',
            label: 'British Indian Ocean Territory',
            phone: '246',
        },
        { code: 'IQ', label: 'Iraq', phone: '964' },
        {
            code: 'IR',
            label: 'Iran, Islamic Republic of',
            phone: '98',
        },
        { code: 'IS', label: 'Iceland', phone: '354' },
        { code: 'IT', label: 'Italy', phone: '39' },
        { code: 'JE', label: 'Jersey', phone: '44' },
        { code: 'JM', label: 'Jamaica', phone: '1-876' },
        { code: 'JO', label: 'Jordan', phone: '962' },
        {
            code: 'JP',
            label: 'Japan',
            phone: '81',
            suggested: true,
        },
        { code: 'KE', label: 'Kenya', phone: '254' },
        { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
        { code: 'KH', label: 'Cambodia', phone: '855' },
        { code: 'KI', label: 'Kiribati', phone: '686' },
        { code: 'KM', label: 'Comoros', phone: '269' },
        {
            code: 'KN',
            label: 'Saint Kitts and Nevis',
            phone: '1-869',
        },
        {
            code: 'KP',
            label: "Korea, Democratic People's Republic of",
            phone: '850',
        },
        { code: 'KR', label: 'Korea, Republic of', phone: '82' },
        { code: 'KW', label: 'Kuwait', phone: '965' },
        { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
        { code: 'KZ', label: 'Kazakhstan', phone: '7' },
        {
            code: 'LA',
            label: "Lao People's Democratic Republic",
            phone: '856',
        },
        { code: 'LB', label: 'Lebanon', phone: '961' },
        { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
        { code: 'LI', label: 'Liechtenstein', phone: '423' },
        { code: 'LK', label: 'Sri Lanka', phone: '94' },
        { code: 'LR', label: 'Liberia', phone: '231' },
        { code: 'LS', label: 'Lesotho', phone: '266' },
        { code: 'LT', label: 'Lithuania', phone: '370' },
        { code: 'LU', label: 'Luxembourg', phone: '352' },
        { code: 'LV', label: 'Latvia', phone: '371' },
        { code: 'LY', label: 'Libya', phone: '218' },
        { code: 'MA', label: 'Morocco', phone: '212' },
        { code: 'MC', label: 'Monaco', phone: '377' },
        {
            code: 'MD',
            label: 'Moldova, Republic of',
            phone: '373',
        },
        { code: 'ME', label: 'Montenegro', phone: '382' },
        {
            code: 'MF',
            label: 'Saint Martin (French part)',
            phone: '590',
        },
        { code: 'MG', label: 'Madagascar', phone: '261' },
        { code: 'MH', label: 'Marshall Islands', phone: '692' },
        {
            code: 'MK',
            label: 'Macedonia, the Former Yugoslav Republic of',
            phone: '389',
        },
        { code: 'ML', label: 'Mali', phone: '223' },
        { code: 'MM', label: 'Myanmar', phone: '95' },
        { code: 'MN', label: 'Mongolia', phone: '976' },
        { code: 'MO', label: 'Macao', phone: '853' },
        {
            code: 'MP',
            label: 'Northern Mariana Islands',
            phone: '1-670',
        },
        { code: 'MQ', label: 'Martinique', phone: '596' },
        { code: 'MR', label: 'Mauritania', phone: '222' },
        { code: 'MS', label: 'Montserrat', phone: '1-664' },
        { code: 'MT', label: 'Malta', phone: '356' },
        { code: 'MU', label: 'Mauritius', phone: '230' },
        { code: 'MV', label: 'Maldives', phone: '960' },
        { code: 'MW', label: 'Malawi', phone: '265' },
        { code: 'MX', label: 'Mexico', phone: '52' },
        { code: 'MY', label: 'Malaysia', phone: '60' },
        { code: 'MZ', label: 'Mozambique', phone: '258' },
        { code: 'NA', label: 'Namibia', phone: '264' },
        { code: 'NC', label: 'New Caledonia', phone: '687' },
        { code: 'NE', label: 'Niger', phone: '227' },
        { code: 'NF', label: 'Norfolk Island', phone: '672' },
        { code: 'NG', label: 'Nigeria', phone: '234' },
        { code: 'NI', label: 'Nicaragua', phone: '505' },
        { code: 'NL', label: 'Netherlands', phone: '31' },
        { code: 'NO', label: 'Norway', phone: '47' },
        { code: 'NP', label: 'Nepal', phone: '977' },
        { code: 'NR', label: 'Nauru', phone: '674' },
        { code: 'NU', label: 'Niue', phone: '683' },
        { code: 'NZ', label: 'New Zealand', phone: '64' },
        { code: 'OM', label: 'Oman', phone: '968' },
        { code: 'PA', label: 'Panama', phone: '507' },
        { code: 'PE', label: 'Peru', phone: '51' },
        { code: 'PF', label: 'French Polynesia', phone: '689' },
        { code: 'PG', label: 'Papua New Guinea', phone: '675' },
        { code: 'PH', label: 'Philippines', phone: '63' },
        { code: 'PK', label: 'Pakistan', phone: '92' },
        { code: 'PL', label: 'Poland', phone: '48' },
        {
            code: 'PM',
            label: 'Saint Pierre and Miquelon',
            phone: '508',
        },
        { code: 'PN', label: 'Pitcairn', phone: '870' },
        { code: 'PR', label: 'Puerto Rico', phone: '1' },
        {
            code: 'PS',
            label: 'Palestine, State of',
            phone: '970',
        },
        { code: 'PT', label: 'Portugal', phone: '351' },
        { code: 'PW', label: 'Palau', phone: '680' },
        { code: 'PY', label: 'Paraguay', phone: '595' },
        { code: 'QA', label: 'Qatar', phone: '974' },
        { code: 'RE', label: 'Reunion', phone: '262' },
        { code: 'RO', label: 'Romania', phone: '40' },
        { code: 'RS', label: 'Serbia', phone: '381' },
        { code: 'RU', label: 'Russian Federation', phone: '7' },
        { code: 'RW', label: 'Rwanda', phone: '250' },
        { code: 'SA', label: 'Saudi Arabia', phone: '966' },
        { code: 'SB', label: 'Solomon Islands', phone: '677' },
        { code: 'SC', label: 'Seychelles', phone: '248' },
        { code: 'SD', label: 'Sudan', phone: '249' },
        { code: 'SE', label: 'Sweden', phone: '46' },
        { code: 'SG', label: 'Singapore', phone: '65' },
        { code: 'SH', label: 'Saint Helena', phone: '290' },
        { code: 'SI', label: 'Slovenia', phone: '386' },
        {
            code: 'SJ',
            label: 'Svalbard and Jan Mayen',
            phone: '47',
        },
        { code: 'SK', label: 'Slovakia', phone: '421' },
        { code: 'SL', label: 'Sierra Leone', phone: '232' },
        { code: 'SM', label: 'San Marino', phone: '378' },
        { code: 'SN', label: 'Senegal', phone: '221' },
        { code: 'SO', label: 'Somalia', phone: '252' },
        { code: 'SR', label: 'Suriname', phone: '597' },
        { code: 'SS', label: 'South Sudan', phone: '211' },
        {
            code: 'ST',
            label: 'Sao Tome and Principe',
            phone: '239',
        },
        { code: 'SV', label: 'El Salvador', phone: '503' },
        {
            code: 'SX',
            label: 'Sint Maarten (Dutch part)',
            phone: '1-721',
        },
        {
            code: 'SY',
            label: 'Syrian Arab Republic',
            phone: '963',
        },
        { code: 'SZ', label: 'Swaziland', phone: '268' },
        {
            code: 'TC',
            label: 'Turks and Caicos Islands',
            phone: '1-649',
        },
        { code: 'TD', label: 'Chad', phone: '235' },
        {
            code: 'TF',
            label: 'French Southern Territories',
            phone: '262',
        },
        { code: 'TG', label: 'Togo', phone: '228' },
        { code: 'TH', label: 'Thailand', phone: '66' },
        { code: 'TJ', label: 'Tajikistan', phone: '992' },
        { code: 'TK', label: 'Tokelau', phone: '690' },
        { code: 'TL', label: 'Timor-Leste', phone: '670' },
        { code: 'TM', label: 'Turkmenistan', phone: '993' },
        { code: 'TN', label: 'Tunisia', phone: '216' },
        { code: 'TO', label: 'Tonga', phone: '676' },
        { code: 'TR', label: 'Turkey', phone: '90' },
        {
            code: 'TT',
            label: 'Trinidad and Tobago',
            phone: '1-868',
        },
        { code: 'TV', label: 'Tuvalu', phone: '688' },
        {
            code: 'TW',
            label: 'Taiwan',
            phone: '886',
        },
        {
            code: 'TZ',
            label: 'United Republic of Tanzania',
            phone: '255',
        },
        { code: 'UA', label: 'Ukraine', phone: '380' },
        { code: 'UG', label: 'Uganda', phone: '256' },
        {
            code: 'US',
            label: 'United States',
            phone: '1',
            suggested: true,
        },
        { code: 'UY', label: 'Uruguay', phone: '598' },
        { code: 'UZ', label: 'Uzbekistan', phone: '998' },
        {
            code: 'VA',
            label: 'Holy See (Vatican City State)',
            phone: '379',
        },
        {
            code: 'VC',
            label: 'Saint Vincent and the Grenadines',
            phone: '1-784',
        },
        { code: 'VE', label: 'Venezuela', phone: '58' },
        {
            code: 'VG',
            label: 'British Virgin Islands',
            phone: '1-284',
        },
        {
            code: 'VI',
            label: 'US Virgin Islands',
            phone: '1-340',
        },
        { code: 'VN', label: 'Vietnam', phone: '84' },
        { code: 'VU', label: 'Vanuatu', phone: '678' },
        { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
        { code: 'WS', label: 'Samoa', phone: '685' },
        { code: 'XK', label: 'Kosovo', phone: '383' },
        { code: 'YE', label: 'Yemen', phone: '967' },
        { code: 'YT', label: 'Mayotte', phone: '262' },
        { code: 'ZA', label: 'South Africa', phone: '27' },
        { code: 'ZM', label: 'Zambia', phone: '260' },
        { code: 'ZW', label: 'Zimbabwe', phone: '263' },
    ];
    const handleCountryCodeChange = (event, newValue) => {
        // Set the country code when a country is selected
        setFormData({
            ...formData,
            countryCode: newValue ? `+${newValue.phone}` : ''
        });
    };

    const handlePhoneInputChange = (event) => {
        // Update phone number as user types
        setFormData({
            ...formData,
            phone: event.target.value
        });
    };
    const completePhoneNumber = `${formData.countryCode} ${formData.phone}`.trim();

    const renderStepContentnew = (step) => {

        switch (step) {
            case 0:
                return (
                    <Box>
                        <Typography variant="h6" component='p' gutterBottom>Where are you traveling to ?</Typography>
                        <TextField
                            variant='outlined'
                            label="Destination"
                            name="destination"
                            required
                            value={formData.destination}
                            onChange={handleChange}
                            error={!!errors.destination}
                            helperText={errors.destination}
                            placeholder='Enter place name (Thailand, Dubai, Kerala)'
                            fullWidth
                            margin="normal"
                            sx={{
                                mt: 0,
                                transition: 'background .3s ease-in-out',

                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: GlobalTheme.palette.primary.main,
                                    borderRadius: 7.5,
                                    backgroundColor: alpha(GlobalTheme.palette.primary.main, .03)
                                }

                            }}
                        />
                        <Typography variant="h6" component='h3' sx={{ mt: 3 }}>I am traveling with:</Typography>
                        <Stack direction='row' justifyContent='space-between'>
                            {/* Adult Counter */}
                            <Box display="flex" alignItems="center" my={2}>
                                <Stack direction={{ lg: 'row' }} spacing={6}>
                                    <Typography>Adults
                                        <Typography component='span' display='block' color={GlobalTheme.palette.common.bodyTxt}>Ages 18 or above</Typography>
                                    </Typography>

                                    <Box display='flex' alignItems='center'>
                                        <Stack direction='row' alignItems='center'>
                                            <IconButton onClick={() => handleAdultChange('remove')} disabled={adultCount === 0}>
                                                <RemoveIcon />
                                            </IconButton>
                                            <TextField value={adultCount} inputProps={{ readOnly: true }} sx={{
                                                width: 50,
                                                "& .MuiOutlinedInput-root": {
                                                    "& .MuiInputBase-input": {
                                                        textAlign: 'center'
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: 'transparent',
                                                    },
                                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: 'transparent',
                                                    },
                                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: 'transparent',
                                                    },
                                                }
                                            }} />
                                            <IconButton onClick={() => handleAdultChange('add')}>
                                                <AddIcon />
                                            </IconButton>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Box>

                            {/* Child Counter */}
                            <Box display="flex" alignItems="center" my={2}>
                                <Stack direction={{ lg: 'row' }} spacing={13}>
                                    <Typography>Children
                                        <Typography component='span' display='block' color={GlobalTheme.palette.common.bodyTxt}>Ages 0-12</Typography>
                                    </Typography>

                                    <Box display='flex' alignItems='center'>
                                        <Stack direction='row' alignItems='center'>
                                            <IconButton onClick={() => handleChildChange('remove')} disabled={childCount === 0}>
                                                <RemoveIcon />
                                            </IconButton>
                                            <TextField value={childCount} inputProps={{ readOnly: true }}
                                                sx={{
                                                    width: 50,
                                                    borderColor: 'transparent',
                                                    "& .MuiOutlinedInput-root": {
                                                        "& .MuiInputBase-input": {
                                                            textAlign: 'center'
                                                        },
                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: 'transparent',
                                                        },
                                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: 'transparent',
                                                        },
                                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: 'transparent',
                                                        },
                                                    }
                                                }} />
                                            <IconButton onClick={() => handleChildChange('add')} disabled={childCount === 5}>
                                                <AddIcon />
                                            </IconButton>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Box>
                        </Stack>

                        {/* Child Age Selectors */}
                        {childCount > 0 && (
                            <Stack mb={2} direction='row'>

                                {childAges.map((age, index) => (
                                    <Stack key={index} mb={3} marginRight={6} flexWrap='wrap'>
                                        <InputLabel id={`label${index}`}>Age of child {index + 1}</InputLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId={`label${index}`}
                                                id={`selectLabel${index}`}
                                                variant="standard"
                                                fullWidth
                                                sx={{ maxWidth: 150 }}
                                                value={age}
                                                label={`Age of child {index + 1}`}
                                                onChange={(event) => handleAgeChange(index, event.target.value)}
                                                displayEmpty
                                            >
                                                {[...Array(12)].map((_, i) => (
                                                    <MenuItem key={i} value={i + 1}>
                                                        {i + 1} years
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                ))}
                            </Stack>
                        )}
                    </Box>
                );
            case 1:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" paragraph marginBottom={2}>When are you planning to travel?</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={4} justifyContent='space-between' direction={{
                                    lg: 'row'
                                }}>
                                    <DatePicker
                                        label="Start travel date"
                                        value={travelDate}
                                        onChange={(newDate) => setTravelDate(newDate)}
                                        minDate={dayjs().add(1, 'day')}  // Disable all dates before tomorrow
                                        error={!!errors.travelDate}  // Pass the error prop to TextField
                                        helperText={errors.travelDate}  // Pass the helperText prop to TextField
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                error={Boolean(errors.travelDate)} // Conditionally show error
                                                helperText={errors.travelDate || ''} // Show helper text when error exists
                                            />
                                        )}
                                        sx={{
                                            width: '100%',
                                            mt: 2, mb: 1,
                                            transition: 'background .3s ease-in-out',

                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: GlobalTheme.palette.primary.main,
                                                borderRadius: 7.5,
                                                backgroundColor: alpha(GlobalTheme.palette.primary.main, .03)
                                            }

                                        }}
                                    />

                                    <DatePicker
                                        label="End travel date"
                                        value={travelEndDate}
                                        onChange={(newDate) => setTravelEndDate(newDate)}
                                        minDate={travelDate ? travelDate.add(1, 'day') : dayjs().add(1, 'day')}
                                        // minDate={dayjs().add(1, 'day')}  // Disable all dates before tomorrow
                                        error={!!errors.travelEndDate}  // Pass the error prop to TextField
                                        helperText={errors.travelEndDate}  // Pass the helperText prop to TextField
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                error={Boolean(errors.travelEndDate)} // Conditionally show error
                                                helperText={errors.travelEndDate || ''} // Show helper text when error exists
                                            />
                                        )}
                                        sx={{
                                            width: '100%',
                                            mt: 2, mb: 1,
                                            transition: 'background .3s ease-in-out',

                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: GlobalTheme.palette.primary.main,
                                                borderRadius: 7.5,
                                                backgroundColor: alpha(GlobalTheme.palette.primary.main, .03)
                                            }

                                        }}
                                    />
                                </Stack>
                            </LocalizationProvider >
                            {errors.travelDate || ''}
                            <Typography component='p' variant='subtitle2' marginTop={1} color='GlobalTheme.palette.common.grayDark' fontWeight={100}>Select "Flexible Dates" to choose a range of travel dates and let us find the best options within your preferred timeframe.</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h6" paragraph marginBottom={2} marginTop={2}>Your trip duration?</Typography>
                            <TextField
                                label="Trip Duration (in days)"
                                name="duration"
                                variant='outlined'
                                placeholder='Exact dates, +- 1,2,3,7,14 days option'
                                value={duration}
                                error={!!errors.duration
                                }
                                fullWidth
                                helperText={errors.duration}
                                onChange={(e) => setDuration(e.target.value)}

                                sx={{
                                    transition: 'background .3s ease-in-out',

                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: GlobalTheme.palette.primary.main,
                                        borderRadius: 7.5,
                                        backgroundColor: alpha(GlobalTheme.palette.primary.main, .03)
                                    }

                                }}
                            />
                        </Grid>
                    </Grid>
                );
            case 2:
                return (
                    <Box>
                        <Typography variant="h6" component='p' gutterBottom>How do you like to travel?</Typography>
                        {/* <TextField
                            label="Details"
                            name="preferences"
                            variant='standard'
                            placeholder='e.g: I want to have a sea facing hotel with bar facility.'
                            value={formData.preferences}
                            onChange={handleChange}
                            multiline
                            minRows={2}
                            maxRows={4}
                            fullWidth
                        /> */}

                        <Autocomplete
                            id="txtTravelPreference"
                            multiple
                            limitTags={2}
                            // value={travelOptions.find(option => option.option === formData.trvlpreferences) || null}
                            // onChange={handleltrvlChange}
                            options={travelOptions}
                            getOptionLabel={(option) => option.option}
                            renderInput={(params) => <TextField {...params} label="Travel Preference" />}
                            sx={{
                                transition: 'background .3s ease-in-out',

                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: GlobalTheme.palette.primary.main,
                                    borderRadius: 7.5,
                                    backgroundColor: alpha(GlobalTheme.palette.primary.main, .03)
                                },

                                "& .MuiChip-root": {
                                    backgroundColor: GlobalTheme.palette.common.white,
                                    boxShadow: `0 0 0 1px #00000021`
                                }

                            }}
                        />



                        <Typography variant="h6" component='p' marginTop={3} gutterBottom>What are you activities are you interested in?</Typography>
                        <Autocomplete
                            id="txtTravelActivity"
                            multiple
                            limitTags={2}
                            // value={travelActivity.find(option => option.option === formData.actpreferences) || null}
                            // onChange={handleltrvlactChange}
                            options={travelActivity}
                            getOptionLabel={(option) => option.option}
                            renderInput={(params) => <TextField {...params} label="Travel Activities" />}
                            sx={{
                                transition: 'background .3s ease-in-out',

                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: GlobalTheme.palette.primary.main,
                                    borderRadius: 7.5,
                                    backgroundColor: alpha(GlobalTheme.palette.primary.main, .03)
                                },

                                "& .MuiChip-root": {
                                    backgroundColor: GlobalTheme.palette.common.white,
                                    boxShadow: `0 0 0 1px #00000021`
                                }

                            }}
                        />

                        {/* <Autocomplete
                            id='txtTravelActivity'
                            disablePortal
                            options={travelActivity}
                            value={formData.actpreferences}
                            name="actpreferences"
                            onChange={handleltrvlactChange}
                            autoHighlight
                            getOptionLabel={(option) => option.option}
                            renderInput={(params) => <TextField {...params} label="Travel Activities" />}

                            sx={{
                                transition: 'background .3s ease-in-out',

                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: GlobalTheme.palette.primary.main,
                                    borderRadius: 7.5,
                                    backgroundColor: alpha(GlobalTheme.palette.primary.main, .03)
                                }

                            }}
                        /> */}
                    </Box>
                );
            case 3:
                return (
                    <Box>
                        <Typography variant="h6" component='p' gutterBottom>Your contact details</Typography>

                        <Grid container spacing={2} marginBottom={3}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Full Name"
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    placeholder='e.g: John'
                                    variant='outlined'
                                    error={!!errors.fullName}
                                    helperText={errors.fullName}


                                    sx={{
                                        my: 1,
                                        transition: 'background .3s ease-in-out',

                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: GlobalTheme.palette.primary.main,
                                            borderRadius: 7.5,
                                            backgroundColor: alpha(GlobalTheme.palette.primary.main, .03)
                                        }

                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>

                                <TextField
                                    label="Last Name"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    placeholder='e.g: Smith'
                                    variant='outlined'
                                    error={!!errors.lastName}
                                    helperText={errors.lastName}
                                    sx={{
                                        my: 1,
                                        transition: 'background .3s ease-in-out',

                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: GlobalTheme.palette.primary.main,
                                            borderRadius: 7.5,
                                            backgroundColor: alpha(GlobalTheme.palette.primary.main, .03)
                                        }

                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} marginBottom={3}>
                            <Grid item xs={12} md={6} display='flex'>
                                <Grid container spacing={2} alignItems='center'>
                                    <Grid item xs={12} md={5}>
                                        <Autocomplete
                                            id="country-code"
                                            options={countries}
                                            getOptionLabel={(option) => `${option.label} (+${option.phone})`}
                                            onChange={handleCountryCodeChange}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props}>
                                                    {option.label} (+{option.phone})
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Country Code"
                                                    variant="outlined"
                                                    placeholder="Country code"
                                                />
                                            )}
                                            sx={{
                                                transition: 'background .3s ease-in-out',

                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: GlobalTheme.palette.primary.main,
                                                    borderRadius: 7.5,
                                                    backgroundColor: alpha(GlobalTheme.palette.primary.main, .03)
                                                }

                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={7}>
                                        <TextField
                                            label="Phone Number"
                                            variant="outlined"
                                            placeholder="Enter phone number"
                                            value={formData.phone}
                                            onChange={handlePhoneInputChange}
                                            sx={{
                                                width: '100%',
                                                transition: 'background .3s ease-in-out',

                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: GlobalTheme.palette.primary.main,
                                                    borderRadius: 7.5,
                                                    backgroundColor: alpha(GlobalTheme.palette.primary.main, .03)
                                                }

                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>

                                <TextField
                                    label="Email"
                                    name="email"
                                    type='email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                    fullWidth
                                    required
                                    placeholder='e.g: yourname@example.com'
                                    variant='outlined'
                                    sx={{
                                        my: 1,
                                        transition: 'background .3s ease-in-out',

                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: GlobalTheme.palette.primary.main,
                                            borderRadius: 7.5,
                                            backgroundColor: alpha(GlobalTheme.palette.primary.main, .03)
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>


                        {/* <DatePicker
                                label="Date of Birth"
                                value={travelEndDate}
                                onChange={(newDate) => setTravelEndDate(newDate)}
                                minDate={travelDate ? travelDate.add(1, 'day') : dayjs().add(1, 'day')}
                                // minDate={dayjs().add(1, 'day')}  // Disable all dates before tomorrow
                                error={!!errors.travelEndDate}  // Pass the error prop to TextField
                                helperText={errors.travelEndDate}  // Pass the helperText prop to TextField
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        error={Boolean(errors.travelEndDate)} // Conditionally show error
                                        helperText={errors.travelEndDate || ''} // Show helper text when error exists
                                    />
                                )}
                                sx={{ mt: 2, mb: 1 }}
                            /> */}

                        {/* <Autocomplete
                                id="phone"
                                fullWidth
                                sx={{
                                    my: 1,
                                    transition: 'background .3s ease-in-out',

                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: GlobalTheme.palette.primary.main,
                                        borderRadius: 7.5,
                                        backgroundColor: alpha(GlobalTheme.palette.primary.main, .03)
                                    }
                                }}
                                required
                                name="phoneCode"
                                value={formData.phone}
                                onChange={handleChange}
                                options={countries}
                                error={!!errors.phone}
                                helperText={errors.phone}
                                autoHighlight
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <Box
                                            key={key}
                                            component="li"
                                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                            {...optionProps}
                                        >
                                            <img
                                                loading="lazy"
                                                width="20"
                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                alt=""
                                            />
                                            {option.label} ({option.code}) +{option.phone}
                                        </Box>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Phone"
                                        slotProps={{
                                            htmlInput: {
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            },
                                        }}
                                    />
                                )}
                            />

                            <TextField
                                label="Phone"
                                name="phone"
                                type='tel'
                                value={formData.phone}
                                onChange={handleChange}
                                fullWidth
                                required
                                placeholder='e.g: +91 xxxx xxx xxx'
                                variant='outlined'
                                error={!!errors.phone}
                                helperText={errors.phone}
                                sx={{
                                    my: 1,
                                    transition: 'background .3s ease-in-out',

                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: GlobalTheme.palette.primary.main,
                                        borderRadius: 7.5,
                                        backgroundColor: alpha(GlobalTheme.palette.primary.main, .03)
                                    }

                                }}
                            /> */}
                        <Box>

                            <Typography variant="h6" component='p' marginTop={5} gutterBottom>Anything we should know about you?:</Typography>
                            <TextField
                                label="Notes"
                                name="notes"
                                type='text'
                                value={formData.notes}
                                fullWidth
                                multiline
                                maxRows={2}
                                placeholder='e.g: Food preferences, occasion, room types, class of service'
                                variant='outlined'
                                sx={{
                                    my: 1,
                                    transition: 'background .3s ease-in-out',

                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: GlobalTheme.palette.primary.main,
                                        borderRadius: 7.5,
                                        backgroundColor: alpha(GlobalTheme.palette.primary.main, .03)
                                    }

                                }}
                            />
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Keep me updated on new destinations" />

                        </Box>
                    </Box>
                );

            case 4:
                return (
                    <Box>
                        <Typography variant="h5" component='h2' gutterBottom>All set and done</Typography>

                        <Box container sx={{ mt: 4 }}>
                            {listData.map((item, index) => (
                                <Grid container sx={{ display: 'flex', flexWrap: { xs: 'wrap', md: 'nowrap' } }} key={index}>
                                    <Grid item xs={12} md={4} xl={3} component="span" mb={2} sx={{ fontWeight: 500 }}>{item.label}</Grid>
                                    <Grid item xs={12} md={8} xl={9} component="span" mb={2} sx={{ color: GlobalTheme.palette.common.bodyTxt }}>{item.value}</Grid>
                                </Grid>
                            ))}
                        </Box>
                    </Box >
                );
            default:
                return 'Unknown step';
        }
    };

    useEffect(() => {
        const updateContentHeight = () => {
            const travelDialog = document.getElementById('travelDialog')?.offsetHeight || 0;
            const logoHeight = document.getElementById('travelLogoHeight')?.offsetHeight || 0;
            const requestNavHeight = document.getElementById('travelRequestNav')?.offsetHeight || 0;

            const calculatedHeight = travelDialog - logoHeight - requestNavHeight;
            setContentHeight(calculatedHeight);
        };
        if (open) {
            // Delay calculation to ensure dialog is rendered
            setTimeout(updateContentHeight, 0);
        }
        // updateContentHeight();
        window.addEventListener('resize', updateContentHeight);

        return () => window.removeEventListener('resize', updateContentHeight);
    }, [open]);

    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />

                <Dialog
                    id='travelDialog'
                    fullScreen
                    open={open}
                    onClose={onClose}
                    TransitionComponent={Transition}
                    sx={{
                        overflow: 'hidden',
                    }}
                >
                    <Grid container sx={{ width: '100%', height: '100%', alignItems: 'flex-start', }}>
                        {isDesktop && (
                            < Grid id='panelLeft' item xs={12} md={6} display={{ xs: 'none', md: 'flex' }} alignItems='center' sx={{ height: '100%', position: 'relative' }}>
                                <CardContent sx={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: alpha(GlobalTheme.palette.common.black, .2)
                                }}>
                                    <Grid item xs={9} sx={{ pl: 5 }}>
                                        <Typography component='h2' variant='h2' marginBottom={3} sx={{ color: GlobalTheme.palette.common.white }}>
                                            Plan Your Perfect Getaway
                                        </Typography>
                                        <Typography component='p' variant='subtitle1' fontWeight={100} sx={{ color: GlobalTheme.palette.common.white, lineHeight: 1.25 }}>
                                            Ready to embark on your next adventure? Whether you're looking for a relaxing beach vacation, an exciting city tour, or a nature-filled escape, we're here to help make your dream trip a reality. Simply fill out the form below with your preferred destination, dates, and travel style, and our team will create a personalized itinerary tailored just for you. Let us handle the details, so you can focus on making unforgettable memories!
                                        </Typography>
                                    </Grid>
                                </CardContent>

                                <CardMedia
                                    component="img"
                                    alt=""
                                    image={BgImage}
                                    sx={{ height: '100%', width: '100%', objectFit: 'cover' }}
                                    loading='lazy'
                                />
                            </Grid>
                        )}
                        <Grid id='panelRight' item xs={12} md={6} display='flex' alignItems='center' sx={{
                            position: 'relative', backgroundColor: GlobalTheme.palette.common.grayLight,
                            height: '100%',
                        }}>
                            <Tooltip title="Close">
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={onClose}
                                    aria-label="close"
                                    sx={{
                                        position: 'absolute',
                                        top: 20,
                                        right: 20
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', height: '100%' }}>
                                {/* <CardContent sx={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    alignContent: 'center'
                                }} id='container'>

                                </CardContent> */}
                                <Container id='travelLogoHeight' sx={{
                                    pt: { xs: 4.5 }
                                }}>
                                    <Box id='logo' sx={{ textAlign: 'center' }}>
                                        <img src={Logo} alt='Aaryan Logo' style={{ width: 140 }} />
                                    </Box>

                                    <Box sx={{
                                        width: '100%'
                                    }}>

                                        <Stepper component='h2' sx={{ mt: 0 }} activeStep={activeStep} alternativeLabel>
                                            {isMobile ? (
                                                <Step key={activeStep}>
                                                    <StepLabel
                                                        sx={{
                                                            fontSize: GlobalTheme.typography.h3.fontSize,
                                                            whiteSpace: 'pre-line',
                                                            '& .MuiStepIcon-root': {
                                                                fontSize: 30,
                                                            },
                                                            '& .MuiStepIcon-text': {
                                                                fill: GlobalTheme.palette.common.white,
                                                                fontWeight: 200,
                                                            },
                                                            '& .MuiStepConnector-root': {
                                                                top: 16,
                                                            },
                                                        }}
                                                    >
                                                        {steps[activeStep].label}
                                                    </StepLabel>
                                                </Step>
                                            ) : (
                                                // Render all steps for desktop view
                                                steps.map((step, index) => (
                                                    <Step key={index}>
                                                        <StepLabel
                                                            sx={{
                                                                fontSize: GlobalTheme.typography.h3.fontSize,
                                                                whiteSpace: 'pre-line',
                                                                '& .MuiStepIcon-root': {
                                                                    fontSize: 30,
                                                                },
                                                                '& .MuiStepIcon-text': {
                                                                    fill: GlobalTheme.palette.common.white,
                                                                    fontWeight: 200,
                                                                },
                                                                '& .MuiStepConnector-root': {
                                                                    top: 16,
                                                                },
                                                            }}
                                                        >
                                                            {step.label}
                                                        </StepLabel>
                                                    </Step>
                                                ))
                                            )}
                                        </Stepper>
                                    </Box>
                                </Container>

                                <Container id='travelContent' maxWidth="md" sx={{ height: contentHeight, overflowY: 'auto' }}>
                                    <Box sx={{ mt: 3 }}>
                                        {renderStepContentnew(activeStep)}
                                    </Box>
                                </Container>

                                <Box id='travelRequestNav' component='nav' sx={{
                                    display: 'flex',
                                    py: 4,
                                    mt: 3,
                                    textAlign: 'right',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    position: 'absolute', left: 0, bottom: 0, right: 0, zIndex: 1,
                                    backgroundColor: GlobalTheme.palette.common.grayLight,

                                    px: {
                                        xs: 2,
                                        sm: 5,
                                        xl: 10
                                    }
                                }} aria-label='travel request nav panel'>
                                    {activeStep !== 0 && (
                                        <Tooltip title='Previous'>
                                            <IconButton variant='contained' color='primary' onClick={handleBack} sx={{ mr: 1 }}>
                                                <ArrowBackIosNewIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {activeStep === 4 && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSubmit}
                                            sx={{ color: GlobalTheme.palette.common.white, borderRadius: 40, boxShadow: 'none', px: 4, py: 1 }}
                                        >
                                            Lets go
                                        </Button>
                                    )}
                                    {activeStep < steps.length - 1 && (
                                        <Tooltip title='Next'>
                                            <IconButton onClick={handleNext} variant='contained' color='primary'>
                                                <ArrowForwardIosIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>
                </Dialog>
                {/* Snackbar for displaying response message */}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Centered snackbar
                >
                    <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </ThemeProvider>
        </React.Fragment >
    );
}
