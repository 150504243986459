

import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import DynamicMantle from './mantles/DynamicMantle';
import { GlobalTheme } from './../theme';
import { Container, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TablePagination from '@mui/material/TablePagination';

import { alpha, Stack } from '@mui/system';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import HomeMantle from './../components/mantles/mantle';
import { common } from '@mui/material/colors';
import SearchBanner from './searchBanner.jpeg'; 

const BASE_MEDIA_URL = 'https://aaryanholidays.in/express/uploads/';

const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};

export default function SearchResultPage() {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const keywords = query.get('keywords');
    const videoRefs = useRef([]);
    const navigate = useNavigate();
    const handleMouseEnter = (index) => {
        const videoElement = videoRefs.current[index];
        if (videoElement && videoElement.paused) {
            videoElement.play().catch(error => console.error('Error playing video:', error));
        }
    };

    const handleMouseLeave = (index) => {
        const videoElement = videoRefs.current[index];
        if (videoElement && !videoElement.paused) {
            videoElement.pause();
            videoElement.currentTime = 0;
        }
    };
    const handleCardClick = (id) => {
        navigate(`/tour-details/4/${id}`)
    }
    useEffect(() => {
        if (keywords) {
            const fetchResults = async () => {
                try {
                    const response = await fetch(`https://aaryanholidays.in/frontend/search-destinations?keywords=${encodeURIComponent(keywords)}`);
                    console.log(`https://aaryanholidays.in/frontend/search-destinations?keywords=${encodeURIComponent(keywords)}`);
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const data = await response.json();
                    setResults(data);
                } catch (error) {
                    console.error('Error fetching search results:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchResults();
        }
    }, [keywords]);

    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />
                {/* <HomeMantle home={1} /> */}
                {/* <DynamicMantle
                    banner_type={tourTypeData.banner_type}
                    banner_src={`${BASE_MEDIA_URL}${tourTypeData.banner_src}`}
                    banner_alt={tourTypeData.banner_alt}
                    banner_title={tourTypeData.banner_title}
                    banner_description={tourTypeData.banner_description}
                /> */}
                <Box position='relative'>
                    <Box position='absolute' display='flex' alignItems='center' justifyContent='center' width='100%' height='100%' textAlign='center'>

                        <Typography id="destinationHeading" component='h1' variant='h1' sx={{ mb: 2 }}>
                            <Typography component='span' variant='h4' sx={{ display: 'block', width: '100%' }} gutterBottom>Search result for</Typography>
                            "{keywords}"
                        </Typography>

                    </Box>
                    <CardMedia
                        component="img"
                        height="500"
                        image={SearchBanner}
                        alt=""
                    />
                </Box>
                <Container fixed sx={{ py: 5 }}>
                    {loading ? (
                        <Typography>Loading...</Typography>
                    ) : results.length === 0 ? (
                        <Typography>No results found</Typography>
                    ) : (
                        <Grid container spacing={4} id='tourListing' sx={{ mt: 2, mb: 4 }}>
                            {results.map((tour, index) => (
                                <Grid
                                    item
                                    id={`tourListing${tour.id}`}
                                    key={tour.id}
                                    xs={12}
                                    marginBottom={3}
                                    sx={{
                                        display: 'inline-flex',
                                        border: `1px solid ${GlobalTheme.palette.common.grayDark}`,
                                        p: 2,
                                        pr: 4, pb: 4,
                                        mb: 7,
                                        borderRadius: 2.5,
                                        backgroundColor: alpha(GlobalTheme.palette.primary.main, .02)
                                    }}
                                >
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} md={3}>
                                            <Card
                                                sx={{
                                                    position: 'relative',
                                                    borderRadius: 2,
                                                    height: 248,
                                                    overflow: 'hidden'
                                                }}>


                                                {tour.type === 'video' ? (
                                                    <CardMedia
                                                        id={`destinationThumbsVid${index}`}
                                                        component="video"
                                                        src={`${BASE_MEDIA_URL}${tour.src}`}
                                                        loop
                                                        muted
                                                        playsInline
                                                        ref={(el) => (videoRefs.current[index] = el)}
                                                    >
                                                        <source src={`${BASE_MEDIA_URL}${tour.src}`} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </CardMedia>
                                                ) : (
                                                    <CardMedia
                                                        id={`destinationThumbsImg${index}`}
                                                        component="img"
                                                        src={`${BASE_MEDIA_URL}${tour.src}`}
                                                        alt={tour.alt}
                                                    />
                                                )}
                                            </Card>
                                        </Grid>
                                        <Grid id='a' item xs={12} md={9} sx={{
                                            display: 'flex', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'space-between'
                                        }}>
                                            <Box>
                                                <Stack direction='row' display='flex' justifyContent='space-between' alignItems='center' sx={{

                                                    mb: 3
                                                }}>
                                                    <Typography variant='h3' component='h3' sx={{ color: GlobalTheme.palette.common.black }} gutterBottom>
                                                        <span dangerouslySetInnerHTML={{ __html: tour.thumbnail_heading }} />
                                                    </Typography>

                                                    <Typography variant='subtitle1' component='p' sx={{ color: GlobalTheme.palette.common.black }} gutterBottom>
                                                        {tour.tour_days}
                                                    </Typography>
                                                </Stack>

                                                <Typography variant="body2" sx={{ color: GlobalTheme.palette.common.bodyTxt }} marginBottom={2}>
                                                    {tour.banner_description}
                                                </Typography>

                                                <Typography variant="body2" sx={{ color: GlobalTheme.palette.common.bodyTxt }}>
                                                    <Typography component='span' fontWeight={500} color={common.grayDark}>Best time to visit:</Typography> <br />{tour.location}
                                                </Typography>
                                            </Box>

                                            <Stack direction='row' display='flex' justifyContent='space-between' alignItems='center'>

                                                <Button id={'destinationListing' + tour.id} variant="text"
                                                    sx={{
                                                        fontWeight: 400,
                                                        mt: 3,
                                                        overflow: 'hidden', // Ensures no overflow on hover
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        transition: 'transform 0.3s ease',
                                                        '&:hover .buttonContent': {
                                                            transform: 'translateX(8px)', // Adjust as needed for animation distance
                                                        },
                                                        '&:hover .icon': {
                                                            opacity: 1,
                                                            transform: 'translateX(0)', // Slide icon in from the left
                                                        },
                                                    }}
                                                    onClick={() => handleCardClick(tour.id)}

                                                >
                                                    <Box
                                                        className="buttonContent"
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            transition: 'transform 0.3s ease',
                                                        }}
                                                    >
                                                        <Box component='span' sx={{ mr: .5 }}>read more</Box>
                                                        <KeyboardArrowRightOutlinedIcon
                                                            className="icon"
                                                            sx={{
                                                                ml: 1, // Add some spacing between text and icon
                                                                opacity: 0, // Initially hidden
                                                                transform: 'translateX(-10px)', // Initial position off to the left
                                                                transition: 'opacity 0.3s ease, transform 0.3s ease', // Smooth transition for appearance
                                                            }}
                                                        />
                                                    </Box>
                                                </Button>


                                                <Typography variant="body2" sx={{
                                                    color: GlobalTheme.palette.common.bodyTxt,
                                                    display: 'flex', alignItems: 'center',
                                                }}>
                                                    <Typography component='span' sx={{
                                                        color: GlobalTheme.palette.common.black,
                                                        display: 'flex', alignItems: 'center',
                                                    }}><CurrencyRupeeIcon fontSize='small' /> {tour.price}</Typography>&nbsp;/Person
                                                </Typography>
                                            </Stack>

                                        </Grid>
                                    </Grid>

                                </Grid>
                            ))}

                        </Grid>
                    )}


                </Container>
            </ThemeProvider>
        </React.Fragment>
    )
}



